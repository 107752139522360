/*
@File: Ribo HTML Template

* This file contains the styling for the actual template, this
is the file you need to edit to change the look of the
template.

This files table contents are outlined below>>>>>

*******************************************
*******************************************
** - Default Btn Style 
** - Section Title Style
** - Navbar Area Style
** - Main Banner Area CSS  Style
** - Inner Banner Style
** - Brand Area Style
** - About Area Style
** - Schedule Area Style
** - Serve Area Style
** - Counter Area Style
** - Progress Area Style
** - Seo Area Style
** - Service Area Style
** - Service Details Area Style
** - Case Studies Area Style
** - History Area Style
** - Work Area Style
** - Mission Area Style
** - Pricing Area Style
** - Team Area Style
** - Testimonial Area Style
** - Blog Area Style
** - Blog Details Area Style
** - Project Area Style
** - Faq Area Style
** - Contact Area Style
** - User All Area Style
** - Footer Area Style
** - 404 Error Area Style
** - Coming Soon Area Style
** - Page-navigation Area Style 
** - Preloader CSS Style
** - Back To Top Button Style
** - Animation CSS Style
*******************************************
/*

/*================================================
Default CSS
=================================================*/
$title-font: 'Livvic', sans-serif;
$body-font: 'Nunito Sans', sans-serif;
$main-color: #ff3900;
$color-blue: #2966be;
$color-black: #1a1b1e;
$color-title: #2a284f;
$paragraph-color: #6c6c6c;
$color-white: #ffffff;

body {
    font-size: 16px;
    line-height: 1.8;
    font-family: $body-font;
    color: $paragraph-color;
}
p {
    color: $paragraph-color; 
}
a {
    display: inline-block;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    text-decoration: none;

    &:hover, &:focus {
        text-decoration: none;
    }
}
.pl-5, .px-5 {
    padding-left: 3rem!important;
}
button {
    margin: 0;
    padding: 0;
    outline: 0;

    &:focus {
        outline: 0 ;
        border: 0;
    }
}
h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
    font-family: $title-font;
    line-height: 1.4;
    color: $color-black;
}
h3 {
    font-size: 20px;
}
.d-table {
    width: 100%;
    height: 100%;
}
.d-table-cell {
    display: table-cell;
    vertical-align: middle;
}
img {
    max-width: 100%;
}
.ptb-100 {
    padding: {
        top: 100px;
        bottom: 100px;
    };
}
.pt-100 {
    padding-top: 100px;
}
.ptb-70 {
    padding: {
        top: 70px;
        bottom: 70px;
    };
}
.pb-100 {
    padding-bottom: 100px;
}
.pb-70 {
    padding-bottom: 70px;
}
.pt-50 {
    padding-top: 50px;
}
.pt-45 {
    padding-top: 45px;
}
.border-radius {
    border-radius: 5px !important;
}
.margin-auto {
    margin: {
        left: auto;
        right: auto;
    };
}
.color-title {
    color: $color-black !important;
}
/*default-btn*/
.default-btn {
    z-index: 1;
    border: none;
    outline: none;
    color: $color-white;
    text-align: center;
    position: relative;
    overflow: hidden;
    border-radius: 50px;
    text-transform: capitalize;
    padding: 12px 65px 12px 27px;
    background-image: linear-gradient(to right,#ff2500,#ff7100);

    i {
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: 20px;
        color: $main-color;
        display: inline-block;
        background-color: $color-white;
        border-radius: 50px;
        text-align: center;
        position: absolute;
        top: 5.5px;
        right: 10px;
        transition: 0.7s;
    }
    &::before {
        content: '';
        position: absolute;
        top: 0;
        width: 0;
        height: 100%;
        background-image: linear-gradient(to right,#0049b1,#1e71e6);
        left: 0;
        right: 0;
        opacity: 0;
        z-index: -1;
        transition: 0.7s;
    }
    &:hover::before {
        width: 100%;
        opacity: 1;
    }
    &:hover i {
        background-color: transparent;
        color: $color-white;
        border: 1px dashed $color-white;
    }
    &:hover {
        color: $color-white;
    }
}
.default-btn-two {
    padding: 12px 27px;
    color: $color-white;
    text-align: center;
    position: relative;
    overflow: hidden;
    z-index: 1;
    text-transform: capitalize;
    background-image: linear-gradient(to right,#ff2500,#ff7100);
    border-radius: 10px;

    i {
        position: relative;
        top: 3px;
    }
    &::before {
        content: '';
        position: absolute;
        top: 0;
        width: 0;
        height: 100%;
        background-image: linear-gradient(to right,#0049b1,#1e71e6);
        left: 0;
        right: 0;
        opacity: 0;
        z-index: -1;
        transition: 0.7s;
    }
    &:hover::before {
        width: 100%;
        opacity: 1;
    }
    &:hover {
        color: $color-white;
    }
}
/*section-title*/
.section-title {
    span {
        line-height: 0;
        text-transform: capitalize;
        padding-bottom: 5px;
        font-weight: 600;
        color: $main-color;
    }
    h2 {
        font-size: 50px;
        font-weight: 600;
        margin-top: 5px;
        line-height: 1.4;

        b {
            font-weight: 300;
        }
    }
    p {
        padding-top: 10px;
        margin-bottom: 0;
    }
}
.sp-before {
    position: relative;
    margin-left: 45px;

    &::before {
        content: '';
        position: absolute;
        bottom: 10px;
        left: -45px;
        width: 45px;
        height: 1px;
        background-image: linear-gradient(to right,#ff2500,#ff7100);
    }
}
.sp-after {
    position: relative;
    margin-right: 45px;

    &::after {
        content: '';
        position: absolute;
        bottom: 10px;
        right: -45px;
        width: 45px;
        height: 1px;
        background-image: linear-gradient(to right,#ff2500,#ff7100);
    }
}
.h2-color {
    color: $color-blue;
}
.h2-color2 {
    color: $color-title;
}

/*=================================
Navbar Area
====================================*/
.navbar-area {
    background-color: transparent;
    transition: 0.3s;
    position: relative;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    padding-left: 0;
}
.navbar-light {
    padding: 0;
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;

    .navbar-brand {
        img{
            float: left;
            padding: 10px 0;
        }
    }
    .navbar-brand-sticky {
        display: none;
    }
}
/* Main nav */
.main-nav {
    position: absolute;
    background-color: transparent;
    top: 0;
    left: 0;
    padding:  0;
    width: 100%;
    z-index: 999;
    height: auto;

    nav {
        .navbar-nav {
            .nav-item {
                &:hover a,.active {
                    color: $main-color !important;
                }
                a {
                    text-transform: capitalize;
                    color: $color-black;
                    font-weight: 600;
                    margin-left: 12px;
                    margin-right: 12px;

                    i {
                        line-height: 0;
                        position: relative;
                        top: 3px;
                        font-size: 18px;
                    }
                    &:hover, :focus {
                        color: $main-color !important;
                    }
                    &.active {
                        color: $main-color !important;
                    }
                    .active::before {
                        transform: scale(1);
                    }
                }
                .dropdown-menu {
                    border: none;
                    border-top: 3px solid $main-color;
                    padding: 0;
                    border-radius: 0;
                    background-color: $color-white !important;

                    li {
                        border-bottom: 1px solid $color-white;

                        &:last-child {
                            border-bottom: none;
                        }
                        a {
                            text-transform: capitalize;
                            color: $color-black !important;
                            position: relative;
                            transition: 0.7s;
                            font-weight: 600;
                            padding: 10px;
                            border-bottom: 1px dashed #ebebeb;

                            &.active{
                                border-radius: .25rem; 
                                color: $main-color !important;
                            }
                            &:hover, :focus, .active{
                                color: $main-color !important;
                                margin-left: 5px;
                                border-radius: .25rem;
                            }
                        }
                        &.active{
                           color: $main-color !important;
                        }
                        .dropdown-menu {
                            left: 100%;
                            top: 20px;

                            li {
                                .dropdown-menu {
                                    left: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
        .side-nav {
            padding: 10px 18px 3px;

            .side-item {
                padding-left: 15px;
                position: relative;
                display: inline-block;

                .search-box {
                    position: relative;
                    top: -12px;

                    i {
                        cursor: pointer;
                        font-size: 18px;
                        color: $main-color;
                        position: relative;
                        display: inline-block;
                        top: -2px;
                        line-height: 35px;
                        border-radius: 50px;
                        width: 35px;
                        height: 35px;
                        background-color: $color-white;
                        text-align: center;
                    }
                    &:hover i {
                        background-color: $color-black;
                        color: $color-white;
                    }            
                }
                .user-btn {
                    position: relative;
                    top: -12px;

                    i {
                        cursor: pointer;
                        font-size: 18px;
                        color: $main-color;
                        position: relative;
                        display: inline-block;
                        top: -2px;
                        line-height: 35px;
                        border-radius: 50px;
                        width: 35px;
                        height: 35px;
                        background-color: $color-white;
                        text-align: center;
                        transition: 0.7s;
                    }
                    &:hover i {
                        background-color: $color-black;
                        color: $color-white;
                    }         
                }
                .nav-menu-btn {
                    padding: 10px 27px;
                    color: $color-white;
                    text-align: center;
                    position: relative;
                    overflow: hidden;
                    z-index: 1;
                    text-transform: capitalize;
                    background-image: linear-gradient(to right,#ff2500,#ff7100);
                    border-radius: 50px;
                    transition: 0.7s;

                    &:hover {
                        background-image: linear-gradient(to right,#4400ff,#0077ff);
                    }
                }
                .search-box-2 {
                    border-color: #f5f5f5;
                    display: inline-block;
                    position: relative;
                    top: -16px;
                    width: 300px;

                    .form-control {
                        height: 50px;
                        width: 300px;
                        border-radius: 5px;

                        &:focus {
                            box-shadow: none !important;
                            border: 1px solid $paragraph-color;
                        }
                    }
                    .search-btn {
                        box-shadow: none;
                        border: none;
                        outline: none;
                        position: absolute;
                        top: 5px;
                        right: 5px;
                        height: 40px;
                        width: 45px;
                        line-height: 42px;
                        background-color: $main-color;
                        border-radius: 10px;
                        color: $color-white;
                        text-align: center;
                        transition: 0.7s;
                        font-size: 20px;

                        &:hover {
                            background-color: $color-blue;
                        }
                    }
                } 
            }
        }
    }
}
.nav-bar-two {
    nav {
        .navbar-nav {
            .nav-item {
                &:hover a,.active {
                    color: $color-white !important;
                }
                a {
                    color: $color-white;
                    position: relative;

                    &:hover, :focus {
                        color: $color-white !important;

                        &::before {
                            opacity: 1;
                            width: 100%;
                        }
                    }
                    &.active {
                        color: $color-white !important;

                        &::before {
                            opacity: 1;
                            width: 100%;
                        }
                    }
                    &::before {
                        content: '';
                        position: absolute;
                        bottom: -3px;
                        left: 0;
                        right: 0;
                        height: 3px;
                        width: 0;
                        background-color: #3f91de;
                        opacity: 0;
                        transition: 0.7s;
                    }
                }
                .dropdown-menu {
                    li {
                        border-bottom: 1px solid $color-white;

                        &:last-child {
                            border-bottom: none;
                        }
                        a {
                            &.active{
                                &::before {
                                    display: none;
                                }
                            }
                            &:hover, :focus, .active{
                                &::before {
                                   display: none;
                                }
                            }
                        }
                        &.active{
                            &::before {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}
.nav-bar-three {
    nav {
        .navbar-nav {
            .nav-item {
                a {
                    position: relative;

                    &:hover, :focus {
                        &::before {
                            opacity: 1;
                            width: 100%;
                        }
                    }
                    &.active {
                        &::before {
                            opacity: 1;
                            width: 100%;
                        }
                    }
                    &::before {
                        content: '';
                        position: absolute;
                        bottom: -3px;
                        left: 0;
                        right: 0;
                        height: 3px;
                        width: 0;
                        background-color: $main-color;
                        opacity: 0;
                        transition: 0.7s;
                    }
                }
                .dropdown-menu {
                    li {
                        border-bottom: 1px solid $color-white;

                        &:last-child {
                            border-bottom: none;
                        }
                        a {
                            &.active{
                                &::before {
                                    display: none;
                                }
                            }
                            &:hover, :focus, .active{
                                &::before {
                                   display: none;
                                }
                            }
                        }
                        &.active{
                            &::before {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}
.sticky-nav {
    top: 0;
    position: fixed;
    animation: 900ms ease-in-out 5s normal none 1 running fadeInDown;
    transition: 0.9s;
    width: 100% !important;
    z-index: 999;

    .navbar-light {
        .navbar-brand {
            img {
                max-width: 130px;
            }
        }
    }
    .main-nav {
        top: 0;
        background-color: $color-white;
        position: fixed;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);

        .search-box {
            i {
                background-color: $main-color !important;
                color: $color-white !important;
            }
            &:hover i {
                background-color: $color-black !important;
                color: $color-white !important;
            }            
        }
        .user-btn {
            i {
                background-color: $main-color !important;
                color: $color-white !important;
            }
            &:hover i {
                background-color: $color-black !important;
                color: $color-white !important;
            }            
        }
    }
    .nav-bar-two {
        top: 0;
        background-color: $color-blue;
        position: fixed;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    }
}
.side-nav-responsive {
    display: none;
    
	.dot-menu {
		padding: 0 10px;
		height: 30px;
		cursor: pointer;
		z-index: 9999;
		position: absolute;
		right: 60px;
        top: 12px;

		.circle-inner {
			display: flex;
			align-items: center;
            height: 30px;
            
			.circle {
				height: 5px;
				width: 5px;
				border-radius: 100%;
				margin: 0 2px;
				transition: 0.7s;
				background-color: $main-color;
			}
		}
		&:hover {
			.circle-inner {
				.circle {
					background-color: $color-blue;
				}
			}
		}
	}
	.container {
        position: relative;
        display: flex;
        max-width: 100%;

		.container {
			position: absolute;
			right: 0;
			top: 55px;
			max-width: 300px;
			margin-left: auto;
			opacity: 0;
			visibility: hidden;
			transition: 0.7s;
			transform: scaleX(0);
			z-index: 2;
			padding-left: 15px;
			padding-right: 15px;
		}
		.container.active {
			opacity: 1;
			visibility: visible;
			transform: scaleX(1);
		}
	}
	.side-nav-inner {
		padding: 10px;
		box-shadow: 0 15px 40px rgba(0, 0, 0, 0.09);
        background-color: $color-white;
       
		.side-nav {
			background-color: $main-color;
			border-radius: 15px;
            padding: 10px 18px 3px;
            
			.side-item {
				margin-left: 15px;
				padding-left: 15px;
                position: relative;
                display: inline-block;
			}
            .search-box {
                position: relative;

                i {
                    cursor: pointer;
                    font-size: 18px;
                    color: $main-color;
                    position: relative;
                    display: inline-block;
                    top: -2px;
                    line-height: 35px;
                    border-radius: 50px;
                    width: 35px;
                    height: 35px;
                    background-color: $color-white;
                    text-align: center;
                }
                &:hover i {
                    background-color: $color-black;
                    color: $color-white;
                }            
            }
            .user-btn {
                position: relative;

                i {
                    cursor: pointer;
                    font-size: 18px;
                    color: $main-color;
                    position: relative;
                    display: inline-block;
                    top: -2px;
                    line-height: 35px;
                    border-radius: 50px;
                    width: 35px;
                    height: 35px;
                    background-color: $color-white;
                    text-align: center;
                    transition: 0.7s;
                }
                &:hover i {
                    background-color: $color-black;
                    color: $color-white;
                }         
            }
            .nav-menu-btn {
                margin-top: 15px;
                padding: 10px 40px;
                color: $color-white;
                text-align: center;
                position: relative;
                overflow: hidden;
                z-index: 1;
                text-transform: capitalize;
                background-image: linear-gradient(to right,#4400ff,#0077ff);
                border-radius: 50px;
                transition: 0.7s;

                &:hover {
                    background-image: linear-gradient(to right,#ff2500,#ff7100);
                }
            }
            .search-box-2 {
                border-color: #f5f5f5;
                display: inline-block;
                max-width: 240px;

                .form-control {
                    height: 50px;
                    width: 240px;
                    border-radius: 5px;

                    &:focus {
                        box-shadow: none !important;
                        border: 1px solid $paragraph-color;
                    }
                }
                .search-btn {
                    box-shadow: none;
                    border: none;
                    outline: none;
                    position: absolute;
                    top: 5px;
                    right: 5px;
                    height: 40px;
                    width: 45px;
                    line-height: 42px;
                    background-color: $main-color;
                    border-radius: 10px;
                    color: $color-white;
                    text-align: center;
                    transition: 0.7s;
                    font-size: 20px;

                    &:hover {
                        background-color: $color-blue;
                    }
                }
            } 
        }
        .side-nav-two {
            padding: 10px 0 3px !important;
        }
	}
}

/*==================================
Search Overlay CSS
====================================*/
.search-overlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 99999;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s ease-in-out;
    overflow: hidden;
    
	.search-layer {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
        transform: translateX(100%);
        
		&:nth-child(1) {
			left: 0;
            background-color: rgba(0, 0, 0, 0.5);
			transition: all 0.3s ease-in-out 0s;
		}
		&:nth-child(2) {
			left: 0;
            background-color: rgba(0, 0, 0, 0.5);
			transition: all 0.3s ease-in-out 0.3s;
		}
		&:nth-child(3) {
			left: 0;
            background-color: rgba(0, 0, 0, 0.5);
			-webkit-transition: all 0.9s ease-in-out 0.6s;
			transition: all 0.9s ease-in-out 0.6s;
		}
	}
	.search-close {
		position: absolute;
		top: 40px;
		right: 40px;
		width: 50px;
		z-index: 2;
		text-align: center;
		cursor: pointer;
		padding: 10px;
		transition: all 0.9s ease-in-out 1.5s;
		opacity: 0;
        visibility: hidden;
        
		.search-close-line {
			width: 100%;
			height: 3px;
			float: left;
			margin-bottom: 5px;
			background-color: $color-white;
            transition: all 500ms ease;
            
			&:nth-child(1) {
				transform: rotate(45deg);
			}
			&:nth-child(2) {
				margin-top: -7px;
				transform: rotate(-45deg);
			}
		}
		&:hover {
			.search-close-line {
				background: $main-color;
				transform: rotate(180deg);
			}
		}
	}
	.search-form {
		transition: all 0.9s ease-in-out 1.4s;
		opacity: 0;
		visibility: hidden;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translateY(-50%) translateX(-50%);
		z-index: 2;
		max-width: 500px;
        width: 500px;
        padding: 0 15px;

		form {
            position: relative;
            
			.input-search {
				display: block;
				width: 100%;
				height: 60px;
				border: none;
				border-radius: 30px;
				color: $color-black;
                padding: 3px 0 0 25px;
                
				&::-webkit-input-placeholder {
					transition: 0.7s;
					letter-spacing: .5px;
					color: $color-black;
				}
				&:-ms-input-placeholder {
					transition: 0.7s;
					letter-spacing: .5px;
					color: $color-black;
				}
				&::-ms-input-placeholder {
					transition: 0.7s;
					letter-spacing: .5px;
					color: $color-black;
				}
				&::placeholder {
					transition: 0.7s;
					letter-spacing: .5px;
					color: $color-black;
				}
				&:focus {
                    border: none;
                    outline: none;

					&::-webkit-input-placeholder {
						color: transparent;
					}
					&:-ms-input-placeholder {
						color: transparent;
					}
					&::-ms-input-placeholder {
						color: transparent;
					}
					&::placeholder {
						color: transparent;
					}
				}
			}
			button {
				position: absolute;
				right: 5px;
				top: 5px;
				width: 50px;
				color: $color-white;
				height: 50px;
				border-radius: 50%;
				background-color: $main-color;
				transition: 0.7s;
				border: none;
				font-size: 20px;
                line-height: 45px;
                
				&:hover {
					background-color: $color-blue;
					color: $color-white;
				}
            }
            &:hover {
                border: none;
                outline: none;
            }
		}
	}
}
.search-overlay.search-overlay-active.search-overlay {
	opacity: 1;
    visibility: visible;
    
	.search-layer {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
	.search-close {
		opacity: 1;
		visibility: visible;
	}
	.search-form {
		opacity: 1;
		visibility: visible;
	}
}

/*==================================
Main Banner Area CSS 
=================================*/
.banner-area {
    position: relative;
}
.banner-shape-right {
    position: absolute;
    top: 0;
    right: 0;

    img {
        max-width: 470px;
    }
}
.banner-img {
    position: absolute;
    right: 0;
    top: 200px;

    img {
        max-width: 840px;
    }
}
.banner-content {
    padding-top: 180px;
    padding-bottom: 140px;
    position: relative;
    z-index: 1;

    .title {
        border: 1px dashed $color-black;
        border-radius: 50px;
        display: inline-block;
        height: 42px;
        line-height: 40px;
        padding-right: 20px;

        i {
            width: 40px;
            height: 40px;
            line-height: 40px;
            font-size: 26px;
            color: $color-white;
            display: inline-block;
            background-image: linear-gradient(to right,#ff2500,#ff7100);
            border-radius: 50px;
            text-align: center;
            margin-right: 5px;
        }
        span {
            color: $color-black;
            position: relative;
            top: -3px;
            font-weight: 600;
        }
    }
    h1 {
        margin-top: 30px;
        margin-bottom: 20px;
        font-size: 82px;
        color: $color-blue;
        line-height: 1.2;

        b {
            font-weight: 300;
        }
    }
    p {
        font-size: 20px;
        color: $color-black;
        max-width: 720px;
    }
    .banner-btn {
        margin-top: 50px;

        .get-btn {
            padding: 12px 65px 12px 27px;
            color: $color-white;
            text-align: center;
            position: relative;
            overflow: hidden;
            z-index: 1;
            text-transform: capitalize;
            background-image: linear-gradient(to right,#ff2500,#ff7100);
            border-radius: 50px;

            i {
                width: 40px;
                height: 40px;
                line-height: 40px;
                font-size: 20px;
                color: $main-color;
                display: inline-block;
                background-color: $color-white;
                border-radius: 50px;
                text-align: center;
                position: absolute;
                top: 5.5px;
                right: 10px;
                transition: 0.7s;
            }
            &::before {
                content: '';
                position: absolute;
                top: 0;
                width: 0;
                height: 100%;
                background-image: linear-gradient(to right,#0049b1,#1e71e6);
                left: 0;
                right: 0;
                opacity: 0;
                z-index: -1;
                transition: 0.7s;
            }
            &:hover::before {
                width: 100%;
                opacity: 1;
            }
            &:hover i {
                background-color: transparent;
                color: $color-white;
                border: 1px dashed $color-white;
            }
        }
        .call-btn {
            margin-left: 20px;
            padding: 12px 27px 12px 65px;
            color: $color-white;
            text-align: center;
            position: relative;
            overflow: hidden;
            z-index: 1;
            text-transform: capitalize;
            background-image: linear-gradient(to right,#0049b1,#1e71e6);
            border-radius: 50px;

            i {
                width: 40px;
                height: 40px;
                line-height: 40px;
                font-size: 20px;
                color: $color-blue;
                display: inline-block;
                background-color: $color-white;
                border-radius: 50px;
                text-align: center;
                position: absolute;
                top: 5.5px;
                left: 10px;
                transition: 0.7s;
            }
            &::before {
                content: '';
                position: absolute;
                top: 0;
                width: 0;
                height: 100%;
                background-image: linear-gradient(to right,#ff2500,#ff7100);
                left: 0;
                right: 0;
                opacity: 0;
                z-index: -1;
                transition: 0.7s;
            }
            &:hover::before {
                width: 100%;
                opacity: 1;
            }
            &:hover i {
                background-color: transparent;
                color: $color-white;
                border: 1px dashed $color-white;
            }
        }
    }
}
.banner-shape {
    .shape1 {
        position: absolute;
        left: 0;
        top: 15%;
        animation: running-in 16s infinite linear;
    }
    .shape2 {
        position: absolute;
        left: 32%;
        top: 15%;
        animation: pulse 7s infinite;
    }
    .shape3 {
        position: absolute;
        left: 25%;
        top: 17%;
        animation: rotated360 6s infinite linear;
    }
    .shape4 {
        position: absolute;
        left: 50%;
        top: 40%;
        animation: rotated360 6s infinite linear;
    }
    .shape5 {
        position: absolute;
        left: 60%;
        top: 25%;
        animation: animationFramesOne 15s linear infinite;
    }
}
.banner-area-two {
    background-image: url(assets/img/home-two/home-two-bg.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 900px;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 900px;
        background-image: url(assets/img/home-two/home-two-bg2.png);
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
    }
}
.banner-content-two {
    padding-top: 170px;
    padding-bottom: 170px;
    
    span {
        color: $color-white;
        background-color: #3b75c9;
        border-radius: 50px;
        width: 130px;
        height: 34px;
        line-height: 34px;
        display: inline-block;
        text-align: center;
        font-size: 15px;
    }
    h1 {
        font-size: 66px;
        color: $color-white;
        font-weight: 600;
        line-height: 1.2;
        margin-top: 15px;
        margin-bottom: 20px;

        b {
           color: $main-color;
           font-weight: 600;
        } 
    }
    p {
        color: $color-white;
        margin-bottom: 0;
    }
    .banner-content-btn {
        margin-top: 30px;

        .learn-btn {
            padding: 12px 65px 12px 27px;
            color: $color-white;
            text-align: center;
            position: relative;
            overflow: hidden;
            z-index: 1;
            text-transform: capitalize;
            background-image: linear-gradient(to right,#ff2500,#ff7100);
            border-radius: 50px;

            i {
                width: 40px;
                height: 40px;
                line-height: 40px;
                font-size: 20px;
                color: $main-color;
                display: inline-block;
                background-color: $color-white;
                border-radius: 50px;
                text-align: center;
                position: absolute;
                top: 5.5px;
                right: 10px;
                transition: 0.7s;
            }
            &::before {
                content: '';
                position: absolute;
                top: 0;
                width: 0;
                height: 100%;
                background-image: linear-gradient(to right,#0049b1,#1e71e6);
                left: 0;
                right: 0;
                opacity: 0;
                z-index: -1;
                transition: 0.7s;
            }
            &:hover::before {
                width: 100%;
                opacity: 1;
            }
            &:hover i {
                background-color: transparent;
                color: $color-white;
                border: 1px dashed $color-white;
            }
        }
    }
    .play-on-btn {
        display: inline-block;
        margin-left: 20px;
        font-size: 16px;
        color: $color-white;
        position: relative;
        overflow: hidden;
        top: -8px;

        i {
            font-size: 20px;
            color: $color-white;
            position: relative;
            top: 3px;
            margin-left: 5px;
        }
        &:hover {
            color: $main-color;
            letter-spacing: 0.25px;

            i {
                color: $main-color;
            }
        }
    }
}
.banner-img-2 {
    margin-top: 200px;
    position: relative;
    z-index: 1;
    animation: movebounce-up 9s infinite;
}
.banner-bottom-shape {
    .shape-one {
        position: absolute;
        bottom: 50px;
        left: 0;
        right: 0;
    }
    .shape-two {
        position: absolute;
        bottom: 70px;
        left: 0;
        right: 0;
    }
    .shape-three {
        position: absolute;
        bottom: 90px;
        left: 0;
        right: 0;
    }
    .banner-dots1 {
        position: absolute;
        top: 22%;
        left: 35%;
        animation: flash 5s infinite;
    }
    .banner-dots2 {
        position: absolute;
        bottom: 13%;
        right: 12%;
        animation: flash 5s infinite;
    }
}
.banner-area-three {
    position: relative;
}
.banner-content-three {
    padding-top: 200px;
    padding-bottom: 170px;

    span {
        color: $main-color;
        background-color: #ffddd9;
        border-radius: 50px;
        width: 195px;
        height: 35px;
        line-height: 35px;
        display: inline-block;
        text-align: center;
        font-size: 15px;
    }
    h1 {
       font-size: 70px;
       color: $color-title;
       font-weight: 600;
       line-height: 1.2;
       margin-top: 15px;
       margin-bottom: 30px;
    }
    p {
        color: $color-title;
        margin-bottom: 0;
        font-size: 18px;
        max-width: 680px;
    }
    .banner-three-btn {
        margin-top: 30px;

        .explore-btn {
            padding: 12px 27px;
            color: $color-white;
            text-align: center;
            position: relative;
            overflow: hidden;
            z-index: 1;
            text-transform: capitalize;
            background-image: linear-gradient(to right,#ff2500,#ff7100);
            border-radius: 10px;

            i {
                position: relative;
                top: 3px;
            }
            &::before {
                content: '';
                position: absolute;
                top: 0;
                width: 0;
                height: 100%;
                background-image: linear-gradient(to right,#0049b1,#1e71e6);
                left: 0;
                right: 0;
                opacity: 0;
                z-index: -1;
                transition: 0.7s;
            }
            &:hover::before {
                width: 100%;
                opacity: 1;
            }
        }
    }
    .play-two-btn {
        display: inline-block;
        margin-left: 20px;
        font-size: 16px;
        font-weight: 600;
        color: $main-color;
        position: relative;
        overflow: hidden;
        top: -8px;

        i {
            font-size: 20px;
            color: $main-color;
            position: relative;
            top: 3px;
            margin-left: 5px;
        }
        &:hover {
            color: $color-black;
            letter-spacing: 0.25px;

            i {
                color: $color-black;
            }
        }
    }
}
.banner-img-3 {
    position: absolute;
    bottom: 0;
    right: 0;

    img {
        max-width: 750px;
        height: 590px;
    }
}
.banner-three-shape {
    .shape1 {
        position: absolute;
        top: 15%;
        left: 40%;
    }
    .shape2 {
        position: absolute;
        bottom: 15%;
        left: 35%;
    }
}

/*================================
Inner Banner
==================================*/
.inner-banner {
    background-image: url(assets/img/inner-banner/banner-bg.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 650px;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 650px;
        background-image: url(assets/img/inner-banner/banner-bg2.png);
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .inner-title {
        padding-top: 250px;
        padding-bottom: 150px;
        position: relative;

        h3 {
            font-size: 40px;
            color: $color-white;
            font-weight: 500;
        }
        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                font-size: 18px;
                color: $color-white;
                display: inline-block;
                margin-right: 10px;

                &:last-child {
                    margin-right: 0; 
                }
                i {
                    font-size: 28px;
                    color: $color-white;
                    position: relative;
                    top: 8px;
                }
                a {
                    color: $color-white;

                    &:hover {
                        color: $main-color;
                    }
                }
            }
        }
        .inner-list {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                font-size: 18px;
                color: $color-white;
                display: inline-block;
                margin-right: 10px;

                &:last-child {
                    margin-right: 0; 
                }
                i {
                    font-size: 24px;
                    color: $color-white;
                    position: relative;
                    top: 8px;
                    margin-right: 5px;
                }
            }
        }
    }
    .inner-banner-shape {
        .shape-one {
            position: absolute;
            bottom: 40px;
            left: 0;
            right: 0;
        }
        .shape-two {
            position: absolute;
            bottom: 60px;
            left: 0;
            right: 0;
        }
        .shape-three {
            position: absolute;
            bottom: 80px;
            left: 0;
            right: 0;
        }
        .inner-banner-dots {
            position: absolute;
            top: 22%;
            left: 35%;
            animation: flash 5s infinite;
        }
        .inner-banner-dots-2 {
            position: absolute;
            bottom: 32%;
            right: 10%;
            animation: flash 5s infinite;
        }
    }
}

/*==================================
Brand Area 
=================================*/
.brand-area {
    position: relative;

    .brand-item {
        img {
            width: unset;
        }
    }
}
.brand-slider {
    max-width: 1140px;
    padding-bottom: 40px;
}
.brand-title {
    h3 {
        font-size: 18px;
        color: $color-black;
        margin-bottom: 40px;

        a {
            color: $main-color;
            border-bottom: 1px solid $main-color;

            &:hover {
                color: $color-blue;
                border-color: $color-blue;
            }
        }
    }
}
.brand-logo-area {
    background-color: #f9f9f9;
    padding: 40px 0;
}
.brand-logo-item {
    img {
        width: unset;
        height: 50px;
    }
}

/*==================================
About Area
=================================*/
.about-area {
    position: relative;
}
.about-img {
    position: relative;
    margin-bottom: 30px;

    img {
        position: relative;
        z-index: 1;
    }
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to right,#ff2500,#ff7100);
        border-radius: 50% 30% 50% 30%;
    }
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: $color-blue;
        z-index: -1;
        border-radius: 30% 40% 30% 40%;
    }
}
.about-content {
    margin-left: 70px;
    margin-bottom: 30px;

    h3 {
        font-size: 20px;
        font-weight: 400;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    p {
        margin-bottom: 0;
    }
    .about-btn {
        margin-top: 40px;

        .play-btn {
            display: inline-block;
            color: $color-black;
            font-size: 16px;
            margin-left: 20px;
            position: relative;
            padding-left: 70px;
            transition: 0.7s;

            span {
                position: relative;
                top: -6px;
            }
            &:hover {
                color: $main-color;
            }
            &:hover i {
                background-image: linear-gradient(to right,#ff2500,#ff7100);
            }
            i {
                position: absolute;
                left: 0;
                top: -7px;
                width: 56px;
                height: 56px;
                line-height: 56px;
                background-color: $color-blue;
                border-radius: 50px;
                font-size: 16px;
                color: $color-white;
                text-align: center;
                display: inline-block;
                transition: 0.7s;
            }
        }
    }
}
.about-shape {
    .shape-1 {
        position: absolute;
        top: 30px;
        right: 0;

        img {
            max-width: 100px;
            height: 610px;
        }
    }
    .shape-2 {
        position: absolute;
        top: 7%;
        right: 30%;
        animation: animationFramesOne 15s linear infinite;
    }
    .shape-3 {
        position: absolute;
        top: 30%;
        right: 10%;
        animation: rotated360 6s infinite linear;
    }
    .shape-4 {
        position: absolute;
        top: 70%;
        right: 7%;
        animation: running-in 16s infinite linear;
    }
    .shape-5 {
        position: absolute;
        top: 80%;
        right: 15%;
        animation: flash 5s infinite linear;
    }
}
.about-content-two {
    .section-title {
        h2 {
            max-width: 485px;
            margin-bottom: 15px;
        }
    }
    h3 {
        margin-bottom: 15px;
        font-weight: 500;
    }
    p {
        margin-bottom: 40px;
    }
    .about-list {
        margin-bottom: 20px;
        padding: 10px 20px 10px 10px;
        background-color: $color-white;
        border-radius: 5px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
        position: relative;
        z-index: 1;

        &:hover {
            &::before {
                opacity: 1;
                width: 100%;
            }
            span {
                color: $color-white;
            }
            p {
                color: $color-white;
            }
        }
        &::before {
            content: '';
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            width: 0;
            height: 100%;
            border-radius: 5px;
            background-image: linear-gradient(to right,#ff2500,#ff7100);
            transition: 0.7s;
            opacity: 0;
        }
        i {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            font-size: 20px;
            color: $color-white;
            display: inline-block;
            width: 35px;
            text-align: center;
            height: 35px;
            line-height: 30px;
            background-color: $color-blue;
            border-radius: 5px 5px 60px 5px;
        }
        span {
            color: $paragraph-color;
            margin-bottom: 10px;
            font-weight: 600;
        }
        p {
            color: $color-black;
            margin-bottom: 0;
            font-weight: 600;
            font-size: 17px;
        }
    }
}
.about-btn-two {
    margin-bottom: 30px;

    .about-more-btn {
        padding: 12px 27px;
        color: $color-white;
        text-align: center;
        position: relative;
        overflow: hidden;
        z-index: 1;
        text-transform: capitalize;
        background-image: linear-gradient(to right,#ff2500,#ff7100);
        border-radius: 10px;

        i {
            position: relative;
            top: 3px;
        }
        &::before {
            content: '';
            position: absolute;
            top: 0;
            width: 0;
            height: 100%;
            background-image: linear-gradient(to right,#0049b1,#1e71e6);
            left: 0;
            right: 0;
            opacity: 0;
            z-index: -1;
            transition: 0.7s;
        }
        &:hover::before {
            width: 100%;
            opacity: 1;
        }
    }
    .contact-us-btn {
        margin-left: 20px;
        padding: 12px 27px;
        color: $color-white;
        text-align: center;
        position: relative;
        overflow: hidden;
        z-index: 1;
        text-transform: capitalize;
        background-color: $color-title;
        border-radius: 10px;

        i {
            position: relative;
            top: 3px;
        }
        &::before {
            content: '';
            position: absolute;
            top: 0;
            width: 0;
            height: 100%;
            background-color: $main-color;
            left: 0;
            right: 0;
            opacity: 0;
            z-index: -1;
            transition: 0.7s;
        }
        &:hover::before {
            width: 100%;
            opacity: 1;
        }
    }
}
.about-widget-content {
    max-width: 610px;

    .section-title {
        h2 {
            max-width: 485px;
            margin-bottom: 15px;
        }
    }
    h3 {
        margin-bottom: 15px;
        font-weight: 500;
    }
    p {
        margin-bottom: 40px;
    }
    .about-widget-list {
        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                display: block;
                position: relative;
                margin-bottom: 20px;

                i {
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    left: 0;
                    font-size: 20px;
                    color: $color-white;
                    display: inline-block;
                    width: 35px;
                    text-align: center;
                    height: 35px;
                    line-height: 30px;
                    background-color: $color-blue;
                    border-radius: 5px 5px 60px 5px;
                }
                span {
                    color: $paragraph-color;
                    margin-bottom: 10px;
                    font-weight: 600;
                    margin-left: 45px;
                }
                p {
                    color: $paragraph-color;
                    margin-bottom: 0;
                    font-weight: 600;
                    font-size: 17px;
                    margin-left: 45px;
                }
            }
        }
    }
    .about-video {
        margin-top: 20px;

        .video-btn {
            i {
                width: 70px;
                display: inline-block;
                text-align: center;
                height: 70px;
                line-height: 60px;
                border-radius: 50px;
                font-size: 20px;
                background-color: $main-color;
                color: $color-white;
                position: relative; 
                border: 5px solid #ffc0b4;

                &:hover {
                    background-color: $color-title;
                    color: $color-white;
                    border-color: $color-title;
                }
                &:hover::after {
                    border-color: $color-title;
                }
                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    border-radius: 50%;
                    border: 5px solid #ff9880;
                    transition: 0.7s;
                }
            }
            &:hover {
                span {
                    color: $color-title;
                }
            }
            span {
                font-size: 17px ;
                font-weight: 600;
                color: $color-blue;
                margin-left: 15px;
            }
        }
    }
}

/*==================================
Schedule Area 
===================================*/
.schedule-width {
    position: relative;
    max-width: 1340px;
    margin: 0 auto;
    background-color: #f9f9f9;
}
.schedule-content {
    max-width: 910px;
    margin: 0 auto;
    background-color: $color-white;
    text-align: center;
    padding: 70px 0;
    position: relative;
    z-index: 1;
    
    h2 {
        font-size: 40px;
        margin-bottom: 20px;
        max-width: 625px;
        margin-left: auto;
        margin-right: auto;
    }
    .schedule-content-shape {
        .shape1 {
            position: absolute;
            bottom: 50px;
            left: 3%;
            animation: running-in 16s infinite linear;
        }
        .shape2 {
            position: absolute;
            bottom: 100px;
            right: 5%;
            animation: rotated360 6s infinite linear;
        }
    }
}
.schedule-shape {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    text-align: center;

    img {
        max-width: 1240px;
    }
}

/*==================================
Serve Area 
=================================*/
.serve-content {
    max-width: 585px;
    margin-left: auto;
    margin-bottom: 30px;

    .serve-list {
        margin-bottom: 20px;
        padding: 10px 20px 10px 40px;
        background-color: $color-white;
        border-radius: 5px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
        position: relative;
        z-index: 1;

        &:hover {
            &::before {
                opacity: 1;
                width: 100%;
            }
            span {
                color: $color-white;
            }
            p {
                color: $color-white;
            }
        }
        &::before {
            content: '';
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            width: 0;
            height: 100%;
            border-radius: 5px;
            background-image: linear-gradient(to right,#ff2500,#ff7100);
            transition: 0.7s;
            opacity: 0;
        }
        i {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            font-size: 20px;
            color: $color-white;
            display: inline-block;
            width: 35px;
            text-align: center;
            height: 35px;
            line-height: 30px;
            background-color: $color-blue;
            border-radius: 5px 5px 60px 5px;
        }
        span {
            color: $paragraph-color;
            margin-bottom: 10px;
            font-weight: 600;
        }
        p {
            color: $color-black;
            margin-bottom: 0;
            font-weight: 600;
            font-size: 16px;
            margin-top: 0;
        }
    }
    p {
        margin-top: 15px;
        margin-bottom: 35px;
    }
}
.serve-img {
    margin-bottom: 30px;
}

/*==================================
Counter Area 
=================================*/
.counter-area {
    background-image: url(assets/img/shape/counter-shape-bg.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 65px 0;
}
.single-counter {
    margin-bottom: 30px;
    margin-top: 30px;

    i {
        position: relative;
        display: inline-block;
        top: -15px;
        left: 0;
        font-size: 50px;
        color: $main-color;
        margin-right: 15px;
    }
    .content {
        display: inline-block;

        h3 {
            font-size: 40px;
            line-height: 0.7;
            margin-bottom: 10px;
            font-weight: 500;
        }
        p {
            color: $color-black;
            font-weight: 400;
            margin-bottom: 0;
        }
    }
}
.counter-area-two {
    background-image: url(assets/img/counter/counter-bg.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 400px;
    position: relative;
}
.counter-card {
    text-align: center;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 20px;
        right: -20px;
        width: 100%;
        height: 60px;
        border-right: 1px dashed $color-white;
    }
    h3 {
        font-size: 40px;
        color: $color-white;
        font-weight: 500;
        line-height: 1.2;
    }
    p {
        color: $color-white;
        margin-bottom: 0;
    }
}
.counter-area-two {
    .col-lg-3 {
        &:nth-child(4) {
            .counter-card {
                &::before {
                    display: none;
                    border-right: none;
                }
            }
        }
    }
}
.counter-shape-top {
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
}

/*==================================
Progress Area
=================================*/
.progress-area {
    .section-title {
        h2 {
            max-width: 600px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}
.progress-card {
    margin-bottom: 30px;
    padding: 60px;
    text-align: center;
    border-radius: 60% 40% 60% 40%;
    position: relative;

    &:hover::before {
        animation: rotated360 6s infinite linear;
    }
    &::before {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        border-radius: 48% 50% 48% 50%;
        transition: 0.7s;
    }
    img {
        margin-bottom: 25px;
    }
    h3 {
        font-size: 40px;
        line-height: 0.7;
        color: $color-white;
        font-weight: 500;
        margin-bottom: 15px;
    }
    p {
        color: $color-white;
        margin-bottom: 0;
    }
}
.pr-bg-color-1 {
    background-color: $color-blue;

    &::before {
        background-image: linear-gradient(to right,#ff2500,#ff7100);
    }
}
.pr-bg-color-2 {
    background-image: linear-gradient(to right,#ff2500,#ff7100);

    &::before {
        background-color: $color-blue;
    }
}
.pr-bg-color-3 {
    background-color: $color-black;
    position: relative;

    &::before {
        background-image: linear-gradient(to right,#ff2500,#ff7100);
    }
}

/*==================================
Seo Area 
=================================*/
.seo-area {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 15px;
        left: 0;
        right: 0;
        height: 225px;
        width: 100%;
        background-image: url(assets/img/seo-bg.png);
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
    }
}
.seo-width {
    max-width: 1190px;
    margin: 0 auto;
    padding: 40px 30px 0 50px;
    background-image: linear-gradient(to right,#ff2500,#ff7100);
    border-radius: 5px;
}
.seo-form {
    h2 {
        font-size: 40px;
        color: $color-white;
        font-weight: 500;
        margin-bottom: 20px;
    }
    .seo-form-area {
        padding: 5px;
        border: 1px solid $color-white;
        position: relative;
        border-radius: 50px;

        .seo-form-option {
            background-color: $color-white;
            border-radius: 50px;
            padding: 7px 0 7px 40px;
        }
        .form-group {
            position: relative;
            margin-bottom: 0;
            
            i {
                position: absolute;
                top: 15px;
                left: 0;
                line-height: 1;
                font-size: 18px;
                color: $main-color;
                z-index: 1;
            }
            .form-control {
                height: 50px;
                padding: 10px 20px 10px 25px;
                border: none;
                background-color: $color-white;
                color: #969696;
                font-weight: 400;
                border-radius: 0;
                
                &.form-border {
                    border-right: 1px solid #ebebeb;
                }
                &:focus {
                    box-shadow: none;
                    outline: none;
                }
            }
        }
        .default-btn {
            margin-left: 20px;
        }
    }
}
.seo-form .seo-form-area .form-group .form-control::-webkit-input-placeholder {
    color: var(--paragraphColor);
}
.seo-form .seo-form-area .form-group .form-control:-ms-input-placeholder {
    color: var(--paragraphColor);
} 
.seo-form .seo-form-area .form-group .form-control::-ms-input-placeholder {
    color: var(--paragraphColor);
}
.seo-form .seo-form-area .form-group .form-control::placeholder {
    color: var(--paragraphColor);
}
.seo-img {
    margin-left: 50px;
}

/*==================================
Service Area 
=================================*/
.services-area {
    position: relative;

    .section-title {
        h2 {
            max-width: 500px;
        }
    }
}
.services-card {
    text-align: center;
    background-color: $color-white;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    padding: 35px 30px;
    position: relative;
    z-index: 1;
    margin-bottom: 30px;

    img {
        margin-bottom: 20px;
        height: 75px;
    }
    h3 {
        font-size: 20px;
        margin-bottom: 10px;

        a {
            color: $color-blue;
        }
    }
    p {
        margin-bottom: 0;
    }
    .services-card-shape {
        position: absolute;
        bottom: 0;
        left: 0;
    }
}
.services-card-into {
    position: relative;

    .services-dots {
        position: absolute;
        z-index: -1;
        right: -70px;
        bottom: -170px;

        img {
            z-index: -1;
        }
    }
}
.services-area {
    .col-lg-4 {
        &:nth-child(1) {
            .services-card-into {
                margin-top: 170px;
            }
        }
        &:nth-child(3) {
            margin-top: -210px;
        }
    }
}
.services-shape {
    .services-bg {
        position: absolute;
        top: 200px;
        left: 0;
    }
    .shape1 {
        position: absolute;
        top: 30%;
        left: 20%;
        animation: flash 5s infinite linear;
    }
    .shape2 {
        position: absolute;
        top: 30%;
        right: 3%;
        animation: rotated360 6s infinite linear;
    }
}
.services-area-two {
    position: relative;
    padding-bottom: 100px;
    padding-top: 50px;

    .section-title {
        max-width: 705px;
        margin-left: auto;
        margin-right: auto;
    }
}
.services-item {
    background-color: $color-white;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
    padding: 50px 30px;
    text-align: center;
    position: relative;
    margin-bottom: 30px;

    &::before {
        content: '';
        position: absolute;
        width: 95%;
        height: 30px;
        text-align: center;
        bottom: -30px;
        margin: 0 auto;
        left: 0;
        right: 0;
        background-color: #f7f7f7;
    }
    &:hover h3 a {
        color: $main-color;
    }
    h3 {
        margin-bottom: 25px;

        a {
            display: block;
            color: #4d4c6b;
        }
    }
    .services-item-img {
        width: 100px;
        height: 100px;
        margin-top: 20px;
        margin-left: auto;
        margin-right: auto;
        position: relative;

        .img-circle {
            position: absolute;
            width: 90px;
            height: 90px;
            text-align: center;
            margin: 0 auto;
            top: 0;
            left: 0;
            right: 0;
            border: 1px dashed $color-black;
            border-radius: 50px;
            animation: rotated360 9s infinite linear;

            &::after {
                content: '';
                position: absolute;
                width: 10px;
                height: 10px;
                text-align: center;
                top: 20px;
                left: 0;
                background-color: $main-color;
                border-radius: 50px;
            }
        }
        a {
            display: block;
            top: 25px;
            position: relative;
            z-index: 1;
            animation: none;

            img {
                animation: none; 
                max-width: 100%;
                height: 40px;
            }
        } 
    }
    p {
        margin-top: 10px;
        margin-bottom: 15px;
    }
    .learn-btn {
        color: $main-color;
        font-weight: 600;

        i {
            position: relative;
            top: 3px;
            margin-left: 10px;
            width: 20px;
            height: 20px;
            line-height: 20px;
            border: 1px dashed $main-color;
            border-radius: 50px;
            text-align: center;
            transition: 0.7s;
        }
        &:hover {
            color: $color-blue;

            i {
                color: $color-blue;
                border-color: $color-blue;
                animation: rotated360 9s infinite linear;
            }
        }
    }
}
.service-two-shape {
    .shape-in1 {
        position: absolute;
        top: 0;
        left: 20%;
        animation: flash 3s infinite linear;
    }
    .shape-in2 {
        position: absolute;
        top: 30%;
        left: 5%;
        animation: animationFramesOne 15s linear infinite;
    }
    .shape-in3 {
        position: absolute;
        top: 40%;
        left: 2%;
        animation: rotated360 6s infinite linear;
    }
    .shape-in4 {
        position: absolute;
        top: 45%;
        right: 5%;
        animation: running-in 16s infinite linear;
    }
    .shape-in5 {
        position: absolute;
        top: 60%;
        right: 2%;
        animation: flash 3s infinite linear;
    }
}
.service-another {
    padding-top: 70px;
    position: relative;

    .section-title {
        h2 {
            max-width: 780px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}
.services-area-three {
    .section-title {
        p {
            max-width: 725px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 10px;
        }
    }
}
.services-item-two {
    text-align: center;
    padding: 30px;

    &:hover {
        h3 {
            a {
                color: $main-color;
            }
        }
    }
    a {
        display: block;

        img {
            max-width: 100%;
            margin-bottom: 20px;
        }
    }
    h3 {
        margin-bottom: 10px;

        a {
            color: $color-title;
        }
    }
    p {
        margin-bottom: 0;
    }
}
.services-another-card {
    margin-bottom: 30px;
    text-align: center;
    padding: 40px 30px;
    background-color: $color-white;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 1;
    border-radius: 5px;

    &:hover {
        &::before {
            height: 100%;
            opacity: 1;
        }
        a {
            .service-icon {
                background-color: $color-white;
                color: $main-color;
            }
        }
        h3 {
            a {
                color: $color-white;
            }
        }
        p {
            color: $color-white;
        }
        .learn-btn {
            color: $color-white;

            i {
                color: $color-white;
                border-color: $color-white;
                animation: rotated360 9s infinite linear;
            }
        }
    }
    &::before {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 0;
        background-image: linear-gradient(to bottom,#ff2500,#ff7100);
        opacity: 0;
        transition: 0.7s;
        border-radius: 5px;
    }
    a {
        display: block;

        .service-icon {
           width: 75px;
           height: 75px;
           line-height: 75px;
           border-radius: 50px;
           font-size: 30px;
           display: inline-block;
           color: $color-white;
           margin-bottom: 20px;
        }   
    }
    h3 {
        margin-bottom: 15px;

        a {
            display: block;
            color: $color-title;
        }
    }
    p {
        margin-bottom: 15px;
    }
    .learn-btn {
        color: $main-color;
        font-weight: 600;

        i {
            position: relative;
            top: 3px;
            margin-left: 10px;
            width: 20px;
            height: 20px;
            line-height: 20px;
            border: 1px dashed $main-color;
            border-radius: 50px;
            text-align: center;
            transition: 0.7s;
        }
        &:hover {
            color: $color-white;

            i {
                color: $color-white;
                border-color: $color-white;
                animation: rotated360 9s infinite linear;
            }
        }
    }
}
.service-another-shape {
    .shape-1 {
        position: absolute;
        top: 10%;
        left: 10%;
        animation: animationFramesOne 15s linear infinite;
    }
    .shape-2 {
        position: absolute;
        top: 50%;
        left: 3%;
        animation: rotated360 6s infinite linear;
    }
    .shape-3 {
        position: absolute;
        top: 80%;
        left: 1%;
        animation: running-in 16s infinite linear;
    }
    .shape-4 {
        position: absolute;
        top: 13%;
        right: 5%;
        animation: running-in 10s infinite linear;
    }
    .shape-5 {
        position: absolute;
        top: 35%;
        right: 3%;
        animation: running-in 16s infinite linear;
    }
    .shape-6 {
        position: absolute;
        top: 55%;
        right: 1%;
        animation: rotated360 6s infinite linear;
    }
}
.service-icon-bg {
    background-color: #96e01e;
}
.service-icon-bg1 {
    background-color: $main-color;
}
.service-icon-bg2 {
    background-color: #175dbf;
}
.service-icon-bg3 {
    background-color: #11aa25;
}
.service-icon-bg4 {
    background-color: #b60db1;
}
.service-icon-bg5 {
    background-color: #f51912;
}
.service-list-content {
    margin-bottom: 30px;
    background-color: $color-white;
    padding: 30px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);

    &:hover h3 {
        color: $main-color;
    }
    h3 {
        font-size: 22px;
        color: $color-title;
        margin-bottom: 10px;
        transition: 0.7s;
    }
    p {
        margin-bottom: 20px;
    }
    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: block;
            margin-bottom: 20px;
            color: $color-black;
            font-size: 17px;
            font-weight: 600;

            &:last-child {
                margin-bottom: 0;
            }
            i {
                font-size: 20px;
                color: $color-white;
                display: inline-block;
                width: 30px;
                text-align: center;
                height: 30px;
                line-height: 25px;
                background-color: $color-blue;
                border-radius: 5px 10px 60px 10px;
                margin-right: 10px;
            }
        }
    }
}

/*==================================
Service Details Area 
=================================*/
.services-left {
    margin-bottom: 30px;

    p {
        margin-bottom: 30px;
    }
}
.services-content {
    img {
        margin-bottom: 30px;
    }
    h2 {
        font-size: 30px;
        color: $color-black;
        margin-bottom: 20px;
    }
    p {
        margin-bottom: 30px;
    }
}
.services-content-card {
    margin-bottom: 30px;
    padding: 30px;
    background-color: $color-white;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    transition: 0.7s;

    &:hover {
        transform: translateY(-5px);

        h3 {
            color: $main-color;
        }
    }
    h3 {
        margin-bottom: 10px;
        color: $color-title;
        transition: 0.7s;
    }
    p {
        margin-bottom: 0;
    }
}
.content-widget-area {
    margin-top: 20px;
    margin-bottom: 30px;

    .content-widget-text {
        h2 {
            font-size: 28px;
            color: $color-black;
            margin-bottom: 20px;
        }
        p {
            margin-bottom: 30px;
        }
        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                display: block;
                margin-bottom: 25px;
                color: $color-black;
                font-size: 17px;
                font-weight: 600;

                &:last-child {
                    margin-bottom: 0;
                }
                i {
                    font-size: 20px;
                    color: $color-white;
                    display: inline-block;
                    width: 30px;
                    text-align: center;
                    height: 30px;
                    line-height: 25px;
                    background-color: $color-blue;
                    border-radius: 5px 10px 60px 10px;
                    margin-right: 10px;
                }
            }
        }
    }
}
.services-widget-list {
    margin-top: 70px;
    margin-bottom: -80px;
    max-width: 780px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 1px;
        top: 60px;
        left: 0;
        right: 0;
        text-align: center;
        border: 1px dashed #cacaca;
    }
    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
            margin-right: 30px;
            position: relative;
            padding: 20px 0;

            &:last-child {
                margin-right: 0;
            }
            &:nth-child(1) {
                top: -15px;
            }
            &:nth-child(2) {
                top: -85px;
            }
            &:nth-child(3) {
                top: -15px;
            }
            &:nth-child(4) {
                top: -85px;
            }
            &.active span {
                background-color: $main-color;
                border-color: #ffb3a5;
                color: $color-white;
            }
            &:hover span {
                background-color: $main-color;
                border-color: #ffb3a5;
                color: $color-white;
            }
            span {
                font-size: 18px;
                width: 75px;
                height: 75px;
                background-color: $color-white;
                color: $main-color;
                border-radius: 50px;
                text-align: center;
                line-height: 65px;
                font-weight: 600;
                box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
                margin-top: 20px;
                margin-bottom: 20px;
                border: 5px solid #fbfbfb;
                transition: 0.7s;
                display: inline-block;
            }
            .content {
                display: block;
                text-align: center;

                h3 {
                    font-weight: 500;
                    line-height: 1;
                }
            }
        }
    }
}
.widget-category {
    margin-bottom: 35px;
    border: 1px dashed $main-color;
    background-color: $color-white;
    box-shadow: 0 0 15px rgba(102, 102, 102, 0.1);

    h3 {
        font-size: 20px;
        color: $color-black;
        border-bottom: 1px dashed $main-color;
        padding: 15px 30px;
        font-weight: 500;
    }
    ul {
        list-style: none;
        margin: 20px 0;
        padding: 0;

        li {
            display:block;
            padding: 10px 30px;
            margin: 10px 10px;
            font-size: 16px;
            background-color: #fef4f0;
            font-weight: 500;

            a {
                color: $main-color;
            }
            &:hover {
                background-color: $main-color;

                a {
                    color: $color-white;
                }
            }
        }
    }
}
.services-widget-two {
    margin-bottom: 35px;
    border: 1px dashed $main-color;
    background-color: $color-white;
    box-shadow: 0 0 15px rgba(102, 102, 102, 0.1);

    h2 {
        font-size: 20px;
        color: $color-black;
        border-bottom: 1px dashed $main-color;
        padding: 15px 30px;
        font-weight: 500;
    }
    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: block;
            position: relative;
            padding-top: 25px;
            padding-bottom: 25px;
            border-bottom: 1px dashed $main-color;

            &:last-child {
                border-bottom: none;
            }
            i {
                font-size: 40px;
                color: $main-color;
                display: inline-block;
                position: absolute;
                top: 18px;
                left: 30px;
            }
            .content {
                display: inline-block;
                margin-left: 95px;

                h3 {
                    font-size: 17px;
                    color: $paragraph-color;
                    margin-bottom: 5px;
                    font-weight: 400;
                }
                span {
                    color: $color-title;
                    font-weight: 600;
                    font-size: 16px;

                    &.active a {
                        color: $main-color;
                    }
                    a {
                        display: block;
                        color: $color-title;
                        font-weight: 600;
                    }
                    &:hover {
                        color: $main-color;
                        a {
                            color: $main-color; 
                        }
                    }
                }
            }
        }
    }
}

/*==================================
Case Studies Area 
=================================*/
.case-studies-content {
    .images-top {
        margin-bottom: 30px;
    }
    span {
        font-size: 16px;
        color: $main-color;
        margin-bottom: 10px;
    }
    h2 {
        font-size: 26px;
        color: $color-title;
        margin-bottom: 20px;
    }
    p {
        margin-bottom: 30px;
    }
}
.case-study-card {
    text-align: center;
    background-color: $color-white;
    margin-bottom: 30px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    transition: 0.7s;

    &:hover {
        transform: translateY(-5px);

        .content {
            h3 {
                a {
                    color: $main-color;
                }
            }
        }
    }
    a {
        display: block;

        img {
            max-width: 100%;
            border-radius: 5px 5px 0 0;
        }
    }
    .content {
        padding: 20px;

        h3 {
            font-size: 22px;
            margin-bottom: 5px;

            a {
                display: block;
                color: $color-title;
                transition: 0.7s;
            }
        }
        span {
            font-size: 16px;
            color: $paragraph-color;
        }
    }
}

/*==================================
History Area
=================================*/
.history-list {
    margin-top: 40px;
    margin-bottom: 70px;
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 1px;
        top: 40px;
        left: 0;
        right: 0;
        text-align: center;
        border: 1px dashed #cacaca;
    }
    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
            margin-right: 100px;

            &:last-child {
                margin-right: 0;
            }
            &.active i {
                background-color: $main-color;
                border-color: #ffb3a5;
                color: $color-white;
            }
            &:hover i {
                background-color: $main-color;
                border-color: #ffb3a5;
                color: $color-white;
            }
            i {
                font-size: 40px;
                width: 75px;
                height: 75px;
                background-color: $color-white;
                color: $main-color;
                border-radius: 50px;
                text-align: center;
                line-height: 65px;
                box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
                margin-bottom: 20px;
                border: 5px solid #fbfbfb;
                transition: 0.7s;
            }
            .content {
                display: block;
                text-align: center;

                h3 {
                    font-weight: 500;
                    line-height: 1;
                }
                span {
                    font-size: 16px;
                    color: $color-black;
                    margin-bottom: 0;
                }
            }
        }
    }
}
.history-content {
    margin-bottom: 30px;

    h2 {
        font-size: 30px;
        margin-bottom: 15px;
    }
    p {
        margin-bottom: 20px;
    }
    .default-btn {
        margin-top: 10px;
    }
}
.history-img {
    margin-bottom: 30px;
    margin-left: 45px;
}

/*==================================
Courses Area 
=================================*/
.courses-area {
    .section-title {
        p {
            max-width: 720px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}
.courses-card {
    margin-bottom: 30px;
    background-color: $color-white;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    padding: 30px 100px 30px 30px;
    position: relative;
    transition: 0.7s;

    &:hover {
        transform: translateY(-10px);

        h3 {
            a {
                color: $main-color;
            }
        }
    }
    .instructor {
        margin-bottom: 15px;

        img {
            display: inline-block;
            margin-right: 10px;
        }
        .name {
            font-size: 16px;
            display: inline-block;
            position: relative;
            top: 3px;

            a {
                display: block;
                color: $color-title;
            }
        }
    }
    h3 {
        max-width: 295px;
        color: $color-title;

        a {
            display: block;
            color: $color-title;
        }
    }
    p {
        max-width: 400px;
        margin-bottom: 15px;
    }
    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
            font-size: 14px;
            margin-right: 20px;

            &:last-child {
                margin-right: 0;
            }
            i {
                font-size: 16px;
                color: $main-color;
                margin-right: 5px;
            }
        }
    }
    .images {
        position: absolute;
        top: 0;
        right: 0;
        background-color: #f1f1f1;
        border-radius: 0 0 0 100px;

        a {
            display: block;

            img {
                max-width: 100%;
            }
        }
        .rate {
            position: absolute;
            top: 10px;
            right: 130px;

            a {
                display: inline-block;
                text-align: center;
                font-size: 16px;
                border-radius: 50px;
                width: 60px;
                height: 60px;
                line-height: 60px;
                color: $color-white;
                font-weight: 600;
                background-image: linear-gradient(to bottom,#ff2500,#ff7100);
            }
        }
    }
}

/*==================================
Work Area 
=================================*/
.work-area {
    background-image: url(assets/img/work-bg.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}
.work-card {
    text-align: center;
    margin-bottom: 30px;

    &.active {
        h2 {
            color: $main-color;

            &::before {
                background-image: linear-gradient(to right,#ff2500,#ff7100) !important;
            }
        }
    }
    h2  {
        font-size: 40px;
        font-weight: 500;
        color: $color-blue;
        width: 130px;
        height: 100px;
        margin: 0 auto;
        line-height: 100px;
        text-align: center;
        background-color: $color-white;
        border-radius: 30% 70% 30% 70%;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: $color-blue;
            border-radius: 70% 30% 70% 30%;
            transform: rotate(20deg);
        }
    }
    h3 {
        font-size: 20px;
        color: $color-blue;
        margin-top: 20px;
        margin-bottom: 10px;
    }
    p {
        margin-bottom: 0;
    }
}
.work-shape {
    position: absolute;
    z-index: -1;
    top: 200px;
    left: 70px;
    right: 0;
}
.work-area-two {
    background-image: url(assets/img/work-bg2.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}
.work-item-list {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: block;
            float: right;
            margin-bottom: 30px;

            h3 {
                color: $color-title;
                margin-bottom: 10px;
                font-weight: 600;
                font-size: 22px;

                span {
                    margin-left: 15px;
                    font-size: 18px;
                    width: 35px;
                    height: 35px;
                    line-height:35px;
                    text-align: center;
                    background-color: $color-white;
                    border-radius: 50px;
                    display: inline-block;
                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        z-index: -1;
                        top: -2px;
                        left: -2px;
                        right: 0;
                        width: 40px;
                        height: 40px;
                        background-color: $color-blue;
                        border-radius: 70% 40% 70% 40%;
                    }
                }
            }
            p {
                margin-bottom: 0;
                margin-right: 50px;
            }
        }
    }
}
.work-item-list-2 {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: block;
            float: left;
            margin-bottom: 30px;

            h3 {
                color: $color-title;
                margin-bottom: 10px;
                font-weight: 600;
                font-size: 22px;

                span {
                    margin-right: 15px;
                    font-size: 18px;
                    width: 35px;
                    height: 35px;
                    line-height:35px;
                    text-align: center;
                    background-color: $color-white;
                    border-radius: 50px;
                    display: inline-block;
                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        z-index: -1;
                        top: -2px;
                        left: -2px;
                        right: 0;
                        width: 40px;
                        height: 40px;
                        background-color: $color-blue;
                        border-radius: 70% 40% 70% 40%;
                    }
                }
            }
            p {
                margin-bottom: 0;
                margin-left: 50px;
            }
        }
    }
}

/*==================================
Mission Area 
=================================*/
.mission-area {
    padding-bottom: 50px;
    position: relative;
}
.mission-content {
    max-width: 580px;
    margin-left: auto;

    p {
        margin-top: 10px;
        margin-bottom: 40px;
    }
    .mission-list {
        margin-bottom: 20px;
        padding: 10px 7px 10px 40px;
        background-color: $color-white;
        border-radius: 5px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
        position: relative;
        z-index: 1;

        &:hover {
            &::before {
                opacity: 1;
                width: 100%;
            }
            span {
                color: $color-white;
            }
            p {
                color: $color-white;
            }
        }
        &::before {
            content: '';
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            width: 0;
            height: 100%;
            border-radius: 5px;
            background-image: linear-gradient(to right,#ff2500,#ff7100);
            transition: 0.7s;
            opacity: 0;
        }
        i {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            font-size: 20px;
            color: $color-white;
            display: inline-block;
            width: 35px;
            text-align: center;
            height: 35px;
            line-height: 30px;
            background-color: $color-blue;
            border-radius: 5px 5px 60px 5px;
        }
        span {
            color: $paragraph-color;
            margin-bottom: 10px;
            font-weight: 600;
        }
        p {
            color: $color-black;
            margin-top: 0;
            margin-bottom: 0;
            font-weight: 600;
            font-size: 16px;
        }
    }
}
.mission-img {
    margin-left: 40px;
}
.mission-shape {
    .shape1 {
        position: absolute;
        top: 10%;
        left: 2%;
        animation: animationFramesOne 15s linear infinite;
    }
    .shape2 {
        position: absolute;
        top: 64%;
        left: 1%;
        animation: rotated360 6s infinite linear;
    }
    .shape3 {
        position: absolute;
        top: 10%;
        right:45%;
        animation: rotated360 15s linear infinite;
    }
    .shape4 {
        position: absolute;
        top: 15%;
        right: 7%;
        animation: running-in 15s linear infinite;
    }
}

/*==================================
Pricing Area 
=================================*/
.pricing-area {
    position: relative;

    .section-title {
        margin-bottom: 45px;
    }
}
.price-width {
    max-width: 1200px;
    background-color: $color-white;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.03);
    padding: 60px;
    margin: 0 auto;
}
.color-1 {
    color: $color-blue;
}
.color-2  {
    color: $main-color;
}
.color-3  {
    color: $color-black;
}
.color-bg1 {
    background-color: #eaf3ff;
}
.color-bg2 {
    background-color: #fef4f0;
}
.color-bg3 {
    background-color: #f1f1f1;
}
.button-bg1 {
    background-image: linear-gradient(to right,#0f0788,#280fb8);

    &:hover {
        background-image: linear-gradient(to right,#ff2500,#ff7100);
    }
}
.button-bg2 {
    background-image: linear-gradient(to right,#ff2500,#ff7100);

    &:hover {
        background-image: linear-gradient(to right,#070100fa,#030200a8);
    }
}
.button-bg3 {
    background-image: linear-gradient(to right,#070100fa,#030200a8);

    &:hover {
        background-image: linear-gradient(to right,#0f0788,#280fb8);
    }
}
.pricing-card {
    border: 1px dashed #94b7e2;
    padding: 5px;
    transition: 0.7s;

    &:hover {
        border-color: $main-color;
    }
    .pricing-card-into {
        text-align: center;
        padding: 0 30px 30px;
        
        .pricing-icon {
            position: relative;
            margin-top: -40px;
            background-color: $color-white;
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.03);
            width: 95px;
            height: 95px;
            font-size: 35px;
            border-radius: 50px;
            display: inline-block;
            text-align: center;
            line-height: 95px;
        }
        h3 {
            font-size: 20px;
            margin-bottom: 15px;
            margin-top: 15px;
            font-weight: 500;
            background-color: $color-white;
            padding: 10px ;
        }
    }
    .price-rate {
        padding-bottom: 10px;
        border-bottom: 1px solid #b7d0ee;
        margin-bottom: 20px;

        h2 {
            font-size: 45px;
            line-height: 1;
            margin-bottom: 0;
        }
        span {
            font-size: 16px;
        }
    }
    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            display: block;
            color: $color-black;
            margin-top: 3px;

            i {
                color: $color-blue;
                font-size: 24px;
                position: relative;
                top: 3px;
            }
            del {
                color: $paragraph-color !important;
            }
        }
    }
    .purchase-btn {
        margin-top: 20px;
        padding: 12px 27px;
        color: $color-white;
        text-align: center;
        position: relative;
        overflow: hidden;
        z-index: 1;
        text-transform: capitalize;
        border-radius: 50px;
        border: none;
        outline: none;
    }
}
.pricing-shape {
    .shape1 {
        position: absolute;
        top: 20%;
        left: 0%;
        animation: running-in 16s infinite linear;
    }
    .shape2 {
        position: absolute;
        top: 50%;
        left: 1%;
        animation: flash 5s infinite linear;
    }
    .shape3 {
        position: absolute;
        bottom: 3%;
        left: 3%;
        animation: animationFramesOne 15s linear infinite;
    }
    .shape4 {
        position: absolute;
        top: 7%;
        right: 7%;
        animation: rotated360 6s infinite linear;
    }
    .shape5 {
        position: absolute;
        top: 25%;
        right: 0;
        animation: flash 5s infinite linear;
    }
    .shape6 {
        position: absolute;
        top: 65%;
        right: 2%;
        animation: running-in 16s infinite linear;
    }
}

/*==================================
Team Area 
=================================*/
.team-area {
    position: relative;

    .section-title {
        p {
           max-width: 700px;
           margin-left: auto;
           margin-right: auto; 
        }
    }
    .center {
        .team-item {
            .team-content {
                transform: scale(1);
            }
        }
    }

}
.team-bg-before {
    &::before {
        background-color: $color-blue;
    }
}
.team-bg-before-2 {
    &::before {
        background-color: $color-black;
    }
}
.team-bg-before-3 {
    &::before {
        background-image: linear-gradient(to right,#ff2500,#ff7100);
    }
}
.team-item {
    text-align: center;
    margin-bottom: 30px;

    .team-img {
        position: relative;
        display: block;

        img {
            border-radius: 40% 60% 40% 60%;
        }
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            border-radius: 60% 40% 60% 40%;
            z-index: -1;
            transform: rotate(23deg);
        }
    }
    &:hover .team-content {
        transform: scale(1);
    }
    .team-content {
        margin-top: 20px;
        transform: scale(0);
        transition: 0.7s;
        
        h3 {
            font-size: 20px;
            margin-bottom: 0;

            a {
                color: $color-blue;
            }
        }
        span {
            color: $color-black;
            margin-bottom: 15px;
        }
        .social-link {
            margin: 15px 0 0;
            padding: 0;
            list-style: none;
            
            li {
                display: inline-block;
                margin-right: 5px;

                a {
                    border-radius: 50px;
                    width: 35px;
                    height: 35px;
                    line-height: 40px;
                    text-align: center;
                    background-color: $color-white;
                    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
                }
                &:nth-child(1) a {
                    color: #3b5998;

                    &:hover {
                        color: $main-color;
                        animation: tada 1s linear;
                    }
                }
                &:nth-child(2) a {
                    color: #55acee;

                    &:hover {
                        color: $main-color;
                        animation: tada 1s linear;
                    }
                }
                &:nth-child(3) a {
                    color: #ff0000;
                    
                    &:hover {
                        color: $main-color;
                        animation: tada 1s linear;
                    }
                }
                &:nth-child(4) a {
                    color: #c80000;

                    &:hover {
                        color: $main-color;
                        animation: tada 1s linear;
                    }
                }
            }
        }
    }
}
.team-shape {
    .team-shape1 {
        position: absolute;
        top: -60px;
        left: 0;
        right: 0;
    }
    .team-shape2 {
        position: absolute;
        top: 20%;
        left: 10%;
        animation: animationFramesOne 10s linear infinite;
    }
    .team-shape3 {
        position: absolute;
        top: 20%;
        right: 10%;
        animation: rotated360 6s infinite linear;
    }
    .team-shape4 {
        position: absolute;
        bottom: 5%;
        right: 40%;
        animation: animationFramesOne 10s linear infinite;
    }
}
.team-two-shape {
    .shape1 {
        position: absolute;
        top: 5%;
        left: 5%;
        animation: animationFramesOne 10s linear infinite;
    }
    .shape2 {
        position: absolute;
        top: 40%;
        left: 2%;
        animation: rotated360 6s infinite linear;
    }
    .shape3 {
        position: absolute;
        top: 60%;
        left: 1%;
        animation: running-in 15s linear infinite;
    }
    .shape4 {
        position: absolute;
        top: 5%;
        right: 10%;
        animation: movebounce-up 4s linear infinite;
    }
    .shape5 {
        position: absolute;
        top: 35%;
        right: 3%;
        animation: rotated360 6s infinite linear;
        
    }
    .shape6 {
        position: absolute;
        top: 65%;
        right: 5%;
        animation: running-in 15s linear infinite;
    }
    .shape7 {
        position: absolute;
        bottom: 0;
        right: 35%;
        animation: animationFramesOne 10s linear infinite;
    }
}
.team-area-two {
    position: relative;

    .section-title {
        h2 {
            max-width: 590px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .owl-nav {
        .owl-prev {
            position: absolute;
            top: 40%;
            left: -90px;
            color: $main-color !important;
            background-color: transparent !important;
            transition: 0.7s;

            i {
                font-size: 16px;
                width: 24px;
                height: 24px;
                line-height: 24px;
                text-align: center;
                border-radius: 50px;
                border: 1px dashed $main-color;
            }
            &:hover {
                color: $color-title !important;
                background-color: transparent !important;

                i {
                    border-color: $color-title;
                }
            }
            &::before {
                content: 'Prev';
                position: absolute;
                top: 0px;
                left: 35px;
                font-size: 16px;
                transition: transform .4s ease;
            }
        }
        .owl-next {
            position: absolute;
            top: 40%;
            right: -90px;
            color: $main-color !important;
            background-color: transparent !important;
            transition: 0.7s;

            i {
                font-size: 16px;
                width: 24px;
                height: 24px;
                line-height: 24px;
                text-align: center;
                border-radius: 50px;
                border: 1px dashed $main-color;
            }
            &:hover {
                color: $color-title !important;
                background-color: transparent !important;

                i {
                    border-color: $color-title;
                }
            }
            &::before {
                content: 'Next';
                position: absolute;
                top: 0px;
                right: 35px;
                font-size: 16px;
                transition: transform .4s ease;
            }
        }
    }
}
.team-card {
    margin-top: 45px;
    text-align: center;
    margin-bottom: 30px;

    &.active .content{
        transform: scale(1);
    }
    &:hover .content{
        transform: scale(1);
    }
    a {
        display: block;

        img {
            max-width: 100%;
            border-radius: 5px;
        }
    }
    .content {
        margin-top: 20px;
        transform: scale(0);
        transition: 0.7s;
        
        h3 {
            font-size: 20px;
            margin-bottom: 0px;
            a {
                color: $color-blue;
            }
        }
        span {
            color: $color-black;
            margin-bottom: 15px;
        }
        .social-link {
            margin: 15px 0 0;
            padding: 0;
            list-style: none;

            li {
                display: inline-block;
                margin-right: 5px;

                a {
                    border-radius: 50px;
                    width: 35px;
                    height: 35px;
                    line-height: 37px;
                    text-align: center;
                    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
                }
                &:nth-child(1) a {
                    color: #3b5998;

                    &:hover {
                        color: $main-color;
                        animation: tada 1s linear;
                    }
                }
                &:nth-child(2) a {
                    color: #55acee;

                    &:hover {
                        color: $main-color;
                        animation: tada 1s linear;
                    }
                }
                &:nth-child(3) a {
                    color: #ff0000;

                    &:hover {
                        color: $main-color;
                        animation: tada 1s linear;
                    }
                }
                &:nth-child(4) a {
                    color: #c80000;

                    &:hover {
                        color: $main-color;
                        animation: tada 1s linear;
                    }
                }
            }
        }
    }
}
.team-card-mt {
    margin-top: 0 !important;
}
.team-rotated {
    transform: rotate(-5deg);
    margin-top: 100px;
}
.team-rotated-2 {
    transform: rotate(-3deg);
    margin-top: 90px;
}

/*==================================
Testimonial Area 
=================================*/
.testimonial-area {
    background-image: url(assets/img/testimonial/testimonial-bg.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}
.testimonial-area-mb {
    margin-bottom: 100px;
}
.testimonial-list {
    margin-left: auto;
    max-width: 610px;
    height: 530px;
    background-color:#ffdfd2;
    border-radius: 70% 50% 70% 50%;
    position: relative;
    border: 30px solid #ffe8df;

    .list-img-1 {
        position: absolute;
        top: -20px;
        left: 5%;
        animation: pulse 1s linear infinite;
    }
    .list-img-2 {
        position: absolute;
        top: 40%;
        left: 0;
        right: 0;
        text-align: center;
        animation: pulse 1s linear infinite;
    }
    .list-img-3 {
        position: absolute;
        top: 60%;
        left: -50px;
        animation: pulse 1s linear infinite;
    }
    .list-img-4 {
        position: absolute;
        top: 5%;
        right: -20px;
        animation: pulse 1s linear infinite;
    }
    .list-img-5 {
        position: absolute;
        bottom: 0;
        right: 110px;
        animation: pulse 2s linear infinite;
    }
}
.testimonial-title {
    h2 {
        font-size: 40px;
        color: $color-title;
        font-weight: 500;
        margin-bottom: 40px;
        margin-left: 70px;
    }
}
.testimonial-slider {
    max-width: 510px;
    margin-left: 70px;
    background-color: $color-white;
    border: 1px dashed #c3c3c3;
    padding: 30px 30px 50px 30px;
    position: relative;

    .owl-nav {
        position: absolute;
        right: 60px;
        bottom: 35px;

        .owl-next {
            position: absolute;
            color: $main-color !important;
            background-color: transparent !important;
            transition: 0.7s;

            i {
                font-size: 16px;
                width: 24px;
                height: 24px;
                line-height: 24px;
                text-align: center;
                border-radius: 50px;
                border: 1px dashed $main-color;
            }
            &:hover {
                color: $color-title !important;
                background-color: transparent !important;

                i {
                    border-color: $color-title;
                }
            }
            &::before {
                content: 'Next';
                position: absolute;
                top: 0px;
                right: 35px;
                font-size: 16px;
                transition: transform .4s ease;
            }
        }
    }
}
.testimonial-item {
    p {
        font-size: 22px;
        color: $color-title;
        margin-bottom: 20px;
    }
    .content {
        display: inline-block;

        img {
            display: inline-block;
            margin-right: 20px;
            width: 70px !important;
        }
        .content-title {
            display: inline-block;
            top: 10px;
            position: relative;
            
            h3 {
                font-size: 17px;
                color: $color-title;
                margin-bottom: 0;
            }
            span {
                font-size: 14px;
                color: $paragraph-color;
            }
            
        }
    }
}
.testimonial-text {
    background-image: url(assets/img/testimonial/testimonial-bg3.png);
    width: 220px;
    height: 175px;
    text-align: center;
    padding: 50px 0;

    h3 {
        font-size: 40px;
        color: $color-black;
        line-height: 1;
    }
    span {
        color: $color-black;
        font-size: 16px;
    }
}
.testimonial-content {
    position: relative;
    max-width: 100%;
    height: 460px;

    .testimonial-content1 {
        position: absolute;
        top: 0;
        left: 0;
    }
    .testimonial-content2 {
        position: absolute;
        top: 7%;
        right: -2%;
    }
    .testimonial-content3 {
        position: absolute;
        top: 50%;
        left: 0;
    }
    .testimonial-content4 {
        position: absolute;
        top: 50%;
        right: 0;
    }
    .testimonial-shape1 {
        position: absolute;
        top: 40%;
        left: 5%;
    }
    .testimonial-shape2 {
        position: absolute;
        top: 39%;
        left: 0;
        right: 0;
        text-align: center;
    }
}
.testimonial-slider-area {
    background-image: url(assets/img/testimonial/testimonial-bg2.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 160px 110px 85px 110px;
}
.testimonial-slider-area {
    position: relative;

    .owl-nav {
        .owl-prev {
            position: absolute;
            color: $color-white !important;
            background-color: transparent !important;
            transition: 0.7s;
            font-size: 30px !important;
            left: 50% !important;
            bottom: -10px;

            &:hover {
                color: $color-title !important;
            }
        }
        .owl-next {
            position: absolute;
            color: $color-white !important;
            background-color: transparent !important;
            transition: 0.7s;
            font-size: 30px !important;
            right: 37% !important;
            bottom: -10px;

            &:hover {
                color: $color-title !important;
            }
        }
    }
}
.testimonial-title-2 {
    h2 {
        font-size: 40px;
        color: $color-white;
        font-weight: 500;
        margin-bottom: 20px;
    }
}
.testimonial-item-2 {
    p {
        font-size: 22px;
        color: $color-white;
        margin-bottom: 20px;
    }
    .content {
        display: inline-block;

        img {
            display: inline-block;
            margin-right: 20px;
            width: 70px !important;
        }
        .content-title {
            display: inline-block;
            top: 10px;
            position: relative;

            h3 {
                font-size: 17px;
                color: $color-white;
                margin-bottom: 0;
            }
            span {
                font-size: 14px;
                color: $color-white;
            }
        }
    }
}
.testimonial-widget-item {
    margin-bottom: 30px;
    background-color: $color-white;
    box-shadow:  0 0 15px rgba(0, 0, 0, 0.1);
    padding: 30px 40px;

    p {
        font-size: 22px;
        color: $color-title;
        margin-bottom: 20px;
    }
    .content {
        display: inline-block;

        img {
            display: inline-block;
            margin-right: 20px;
            width: 70px !important;
        }
        .content-title {
            display: inline-block;
            top: 10px;
            position: relative;

            h3 {
                font-size: 17px;
                color: $color-title;
                margin-bottom: 0;
            }
            span {
                font-size: 14px;
                color: $paragraph-color;
            }
            
        }
    }
}

/*==================================
Blog Area 
=================================*/
.blog-area {
    position: relative;

    .section-title {
        p {
            max-width: 620px;
        }
    }
}
.blog-bg {
    background-image: url(assets/img/blog/blog-bg.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}
.blog-bg2 {
    position: relative;
    z-index: 1;

    &::before {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        background-color:#f9f9f9 ;
        max-width: 1740px;
        margin: 0 auto;
        height: 80%;
    }
}
.blog-title {
    margin-bottom: 45px;

    .section-title {
        p {
            max-width: 620px;
        }
    }
}
.blog-btn {
    float: right;
    margin-top: 120px;
    margin-bottom: 45px;
}
.blog-card {
    margin-bottom: 30px;
    background-color: $color-white;
    transition: 0.7s;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.03);

    &:hover {
        transform: translateY(-10px);

        .content {
            h3 {
                a {
                    color: $main-color !important;
                }
            }
        }
    }
    a {
        display: block;

        img {
            max-width: 100%;
        }
    }
    .content {
        padding: 20px 30px;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                display: inline-block;
                font-size: 14px;
                color: $paragraph-color;
                margin-right: 30px;

                &:last-child {
                    margin-right: 0;
                }
                i {
                    color: $main-color;
                    font-size: 16px;
                    margin-right: 5px;
                    position: relative;
                    top: 3px;
                }
                a {
                    color: $paragraph-color;
                    display: inline-block;

                    &:hover {
                        color: $color-black;
                    }
                }
            }
        }
        h3 {
            margin-top: 5px;
            font-size: 22px;
            margin-bottom: 0;

            a {
                display: block;
                color: $color-blue;
            }
            .title-color {
                color: $color-title !important;
            }
        }
    }
}
.blog-shape {
    .shape1 {
        position: absolute;
        top: 30%;
        left: 2%;
        animation: animationFramesOne 10s linear infinite;
    }
    .shape2 {
        position: absolute;
        top: 60%;
        left: 4%;
        animation: animationFramesOne 15s linear infinite;
    }
    .shape3 {
        position: absolute;
        top: 50%;
        right: 2%;
        animation: flash 5s infinite linear;
    }
    .shape4 {
        position: absolute;
        top: 80%;
        right: 4%;
        animation: rotated360 6s infinite linear;
    }
}
.blog-area-widget {
    .title {
        h2 {
            font-size: 30px;
            color: $color-title;
            margin-bottom: 30px;
        }
    }
}

/*==================================
Blog Details Area
=================================*/
.blog-details-area {
    padding-top: 50px;
}
.blog-article {
    .blog-article-img {
        margin-bottom: 30px;
    }
    .article-content {
        h2 {
            font-size: 30px;
            margin-bottom: 20px;
            font-weight: 500;
        }
        .content-text {
            position: relative;

            &::before {
                content: 'S';
                position: absolute;
                font-size: 95px;
                color: $color-title;
                top: -40px;
                left: 0;
                font-weight: 600;
            }
            p {
                margin-left: 75px;
                margin-bottom: 10px;
            }
        }
        .blockquote {
            text-align: center;
            position: relative;
            margin-bottom: 20px;
            margin-top: 40px;
            background-color: #fff6f2;
            padding: 20px;
            border: 1px dashed $main-color;

            &::before {
                content: '\ecff';
                position: absolute;
                top: -40px;
                right: 0;
                left: 0;
                text-align: center;
                font-size: 50px;
                font-weight: 400;
                color: $main-color;
                font-family: boxicons;
            }
            p {
                font-size: 21px;
                color: $color-black;
                font-weight: 600;
                margin-top: 20px;
                margin-right: 65px;
                margin-left: 65px;
                text-align: center;
            }
            span {
                text-align: center;
                font-size: 14px;
                color: $main-color;
                margin-left: 30px;
                margin-bottom: 20px;
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: -30px;
                    width: 30px;
                    height: 1px;
                    background-color: $main-color;
                }
            }
        }
    }
    .another-content {
        h2 {
            font-size: 30px;
            margin-bottom: 20px;
            font-weight: 500;
        }
        p {
            margin-bottom: 30px;
        }
        .content-img {
            margin-bottom: 30px;
        }
    }
    .blog-article-share {
        border: 1px dashed $main-color;
        background-color: #fff6f2;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-bottom: 40px;

        .blog-tag {
            ul {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    display: inline-block;
                    padding: 5px 8px;
                    transition: 0.7s;
                    color: $color-black;
                    font-weight: 600;

                    a {
                        color: $main-color;  
                    }
                    &:hover a {
                        color: $color-title;
                    }
                }
            }
        }
        .social-icon {
            margin: 0;
            padding: 0;
            list-style: none;
            float: right;

            li {
                display: inline-block;
                margin-right: 5px;

                a {
                    border-radius: 50px;
                    width: 30px;
                    height: 30px;
                    line-height: 32px;
                    text-align: center;
                    color: $color-white;
                }
                &:nth-child(1) a {
                    background-color: #3b5998;

                    &:hover {
                        background-color: $main-color;
                        animation: tada 1s linear;
                    }
                }
                &:nth-child(2) a {
                    background-color: #55acee;

                    &:hover {
                        background-color: $main-color;
                        animation: tada 1s linear;
                    }
                }
                &:nth-child(3) a {
                    background-color: #3f729b;

                    &:hover {
                        background-color: $main-color;
                        animation: tada 1s linear;
                    }
                }
                &:nth-child(4) a {
                    background-color: #ff0000;

                    &:hover {
                        background-color: $main-color;
                        animation: tada 1s linear;
                    }
                }
                &:nth-child(5) a {
                    background-color: #c80000;

                    &:hover {
                        background-color: $main-color;
                        animation: tada 1s linear;
                    }
                }
            }
        }
    }
    .article-author {
        margin-bottom: 40px;
        border-radius: 5px;
        background-color: $color-white;
        position: relative;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
        padding: 60px 40px 10px;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 150px;
            background-color: #0049b1;
            border-radius: 5px 5px 0 0;
        }
        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                position: relative;
                z-index: 1;
                padding: 30px;
                padding-left: 150px;
                margin-bottom: 30px;

                &:last-child {
                    margin-bottom: 0;
                    border: none;
                }
                img {
                    border-radius: 50%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    border: 15px solid $color-white;
                }
                h3 {
                    margin-bottom: 0;
                    font-size: 20px;
                    color: $color-white;
                    font-weight: 500;
                }
                span {
                    margin-bottom: 10px;
                    display: block;
                    color: $color-white;
                }
                p {
                    margin-top: 30px;
                    margin-bottom: 0;
                }
            }
        }
        .author-social-link {
            position: absolute;
            top: 110px;
            right: 40px;

            .social-icon {
                margin: 0;
                padding: 0;
                list-style: none;
                float: right;

                li {
                    display: inline-block;
                    margin-right: 3px;
                    padding: 0;
                    margin-bottom: 0;

                    a {
                        border-radius: 50px;
                        width: 30px;
                        height: 30px;
                        line-height: 32px;
                        text-align: center;
                        color: $color-white;
                    }
                    &:hover a{
                        color: $color-black;
                    }
                }
            }
        }
        .author-shape {
            .shape1 {
                position: absolute;
                top: 0;
                left: 0;
            }
            .shape2 {
                position: absolute;
                top: 40px;
                left: 0;
                right: 0;
                text-align: center;
            }
            .shape-dots {
                position: absolute;
                top: 10%;
                right: 30%;
            }
        }
    }
    .article-post {
        position: relative;
        margin-bottom: 40px;

        &::before {
            content: '';
            position: absolute;
            top: 30px;
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 1px;
            height: 60px;
            background-color: #eeeeee;
        }
        .article-post-share {
            padding: 20px 30px;
            border: 1px dashed $main-color;

            span {
                font-size: 14px;
                color: $main-color;
                position: relative;
                font-weight: 600;
                margin-left: 40px;
                display: inline-block;

                &::before {
                    content: '';
                    position: absolute;
                    left: -35px;
                    width: 30px;
                    height: 1px;
                    bottom: 0;
                    background-color: $main-color;
                }
                a {
                    color: $main-color;
                    display: inline-block;
                    margin-bottom: 0;

                    &:hover {
                        color: $color-title;
                    }
                }
            }
            a {
                display: block;
                
                h3 {
                    font-size: 20px;
                    margin-bottom: 0;
                    margin-top: 15px;
                    transition: 0.7s;
                    max-width: 600px;
                    color: $color-title;

                    &:hover {
                        color: $main-color;
                    }
                }
            }
        }
    }
    .comments-wrap {
        margin-top: 20px;
        padding-top: 20px;

        .title {
            font-size: 24px;
            margin-bottom: 20px;
            color: $color-title;
            font-weight: 500;
        }
        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                position: relative;
                padding: 30px;
                padding-left: 150px;
                margin-bottom: 30px;
                border-bottom: 1px solid #eeeeee;

                &:last-child {
                    margin-bottom: 0;
                    border: none;
                }
                img {
                    border-radius: 50%;
                    position: absolute;
                    top: 35px;
                    left: 30px;
                }
                h3 {
                    margin-bottom: 0;
                    font-size: 20px;
                    font-weight: 500;
                }
                span {
                    margin-bottom: 10px;
                    display: block;
                }
                p {
                    margin-bottom: 0;
                }
                a {
                    position: absolute;
                    top: 30px;
                    right: 30px;
                    color: $main-color;
                    font-weight: 500;

                    &:hover {
                        color: $color-title;
                    }
                }
                &.ml-30 {
                    margin-left: 30px;
                }
            }
        }
    }
    .comments-form {
        margin-top: 20px;
        margin-bottom: 30px;

        .contact-form {
            background-color: #f4f4f4 !important;

            .form-group {
                .form-control {
                    border-radius: 25px;
                }
            }
        }
    }
}
.blog-widget-left {
    margin-bottom: 30px;
}
.blog-widget {
    margin-bottom: 35px;
    border: 1px dashed $main-color;
    background-color: $color-white;
    box-shadow: 0 0 15px rgba(102, 102, 102, 0.1);

    .title {
        font-size: 20px;
        color: $color-black;
        border-bottom: 1px dashed $main-color;
        padding: 15px 30px;
        font-weight: 500;
    }
    .search-widget {
        padding: 20px 30px 30px;

        .search-form {
            position: relative;

            .form-control {
                height: 50px;
                border: 1px dashed $main-color;
                background-color: $color-white;
                padding: 10px 20px;
                width: 100%;
                border-radius: 0;

                &:focus {
                    box-shadow: none;
                    outline: 0;
                }
            }
            button {
                position: absolute;
                top: 0;
                right: 0;
                height: 50px;
                width: 50px;
                background-image: linear-gradient(to right,#ff2500,#ff7100);
                transition: all 0.5s;
                border: none;
                outline: none;

                i {
                    color: $color-white;
                }
                &:hover {
                    background-image: linear-gradient(to right,#001aff,#15094d);
                }
            }
        }
    }
    .widget-popular-post {
        position: relative;
        overflow: hidden;
        padding: 20px  30px 30px;

        .item {
            overflow: hidden;
            margin-bottom: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid #efefef;

            &:last-child {
                margin-bottom: 0;
                border-bottom: none;
                padding-bottom: 0;
            }
            .thumb {
                float: left;
                overflow: hidden;
                position: relative;
                margin-right: 15px;

                .full-image {
                    width: 75px;
                    height: 105px;
                    display: inline-block;
                    background-size: cover !important;
                    background-repeat: no-repeat;
                    background-position: center center !important;
                    position: relative;
                    background-color: var(--titleColor);

                    &.bg1 {
                        background-image: url(assets/img/blog/blog-post1.png);
                    }
                    &.bg2 {
                        background-image: url(assets/img/blog/blog-post2.png);
                    }
                    &.bg3 {
                        background-image: url(assets/img/blog/blog-post3.png);
                    }
                    
                }
            }
            .info {
                overflow: hidden;

                span {
                    display: block;
                    color: $main-color;
                    text-transform: uppercase;
                    margin-top: 5px;
                    margin-bottom: 5px;
                    font-size: 14px;
                    font-weight: 600;
                }
                .title-text {
                    margin-bottom: 0;
                    line-height: 1.5;
                    font-size: 15px;
                    font-weight: 600;

                    a {
                        display: inline-block;
                        color: $color-title;

                        &:hover {
                            color: $main-color;
                        }
                    }
                }
                p {
                    font-size: 14px;
                    margin-bottom: 0;
                    max-width: 180px;
                }
            }
        }
    }
    .widget_categories {
        padding: 20px 30px;

        ul {
            padding: 0;
            margin: 0;
            list-style-type: none;

            li {
                position: relative;
                padding-bottom: 10px;
                margin-bottom: 10px;
                font-size: 15px;
                font-weight: 600;
                border-bottom: 1px solid #ededed;
                
                a {
                    display: block;
                    color: $paragraph-color;
                    font-weight: normal;

                    span {
                        float: right;
                        font-size: 13px;
                        color: $main-color;
                        font-weight: 600;
                    }
                    &:hover {
                        color: $main-color;
                    }
                }
            }              
        }
    }
    .blog-widget-tag {
        list-style: none;
        margin: 0;
        padding: 10px 20px 20px;

        li {
            display: inline-block;
            padding: 0 5px;
            transition: 0.7s;
            color: #777777;
            font-size: 16px;
            font-weight: 600;

            a {
                color: #777777;
            }
            &:hover a {
                color: $main-color;
            }
        }
    }
}

/*==================================
Project Area
=================================*/
.project-area {
    background-image: linear-gradient(to right,#ff2500,#ff7100);
    padding: 65px 0;
    max-width: 1740px;
    margin: 0 auto;
    position: relative;
}
.project-content {
    text-align: center;
    max-width: 860px;
    margin: 0 auto;

    h2 {
        font-size: 40px;
        color: $color-white;
        margin-bottom: 30px;
    }
    .project-btn {
        padding: 12px 30px;
        color: $main-color;
        text-align: center;
        position: relative;
        overflow: hidden;
        z-index: 1;
        text-transform: capitalize;
        background-color: $color-white;
        border-radius: 10px;

        i {
            position: relative;
            top: 3px;
        }
        &::before {
            content: '';
            position: absolute;
            top: 0;
            width: 0;
            height: 100%;
            background-color: $color-title;
            left: 0;
            right: 0;
            opacity: 0;
            z-index: -1;
            transition: 0.7s;
        }
        &:hover::before {
            width: 100%;
            opacity: 1;
        }
        &:hover {
            color: $color-white;
        }
    }
}
.project-shape {
    .shape1 {
        position: absolute;
        top: 0;
        left: 0;
    }
    .shape2 {
        position: absolute;
        top: 0;
        right: 0;
    }
}

/*==================================
Faq Area 
=================================*/
.faq-accordion {
    max-width: 470px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 30px;

    .accordion {
        list-style-type: none;
        padding-left: 0;
        margin-bottom: 0;

        .accordion-item {
            display: block;
            background-color: $color-white;
            margin-bottom: 15px;
            box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);

            &:last-child {
                margin-bottom: 0;
            }
        }
        .accordion-title {
            padding: 20px 60px 17px 20px;
            color: #232350;
            text-decoration: none;
            position: relative;
            display: block;
            font-size: 18px;
            font-weight: 600;

            i {
                position: absolute;
                right: 15px;
                top: 15px;
                font-size: 25px;
                transition: 0.5s;
                font-size: 24px;
                width: 40px;
                height: 40px;
                color: $color-white;
                text-align: center;
                line-height: 40px;
                background-color: $color-title;
            }
            &.active i {
                transform: rotate(180deg);
                top: 15px;
            }
        }
        .accordion-content {
            display: none;
            position: relative;
            margin-top: -5px;
            padding-bottom: 10px;
            padding-right: 30px;
            padding-left: 30px;
            
            p {
                line-height: 1.8;
            }
            &.show {
                display: block;
            }
        }
    }
}
.faq-contact-wrap {
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
}
.faq-img {
    margin-bottom: 30px;
}
.data-table-area {
    padding-top: 60px;
    padding-bottom: 85px;
    background-image: url(assets/img/data-table-bg.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
}
.data-table-content {
    margin-top: 40px;

    h2 {
        font-size: 40px;
        color: $color-white;
        margin-bottom: 20px;
        max-width: 430px;
    }
}

/*==================================
Contact Area 
===================================*/
.contact-area {
    padding-top: 50px;
}
.contact-card {
    margin-bottom: 30px;
    background-color: $color-white;
    padding: 50px 30px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
    text-align: center;

    i {
        font-size: 60px;
        color: $main-color;
        margin-bottom: 10px;
    }
    h3 {
        color: $color-blue;
        margin-bottom: 10px;
    }
    p {
        margin-bottom: 0;
        font-size: 16px;
    }
    .contact-card-btn {
        margin-top: 20px;
        color: $main-color;
        font-size: 16px;
        font-weight: 600;

        .plus-btn {
            position: relative;
            top: 3px;
            font-size: 16px;
            margin-left: 10px;
            width: 20px;
            height: 20px;
            line-height: 20px;
            border: 1px dashed $main-color;
            border-radius: 50px;
            text-align: center;
            transition: 0.7s;
        }
    }
} 
.contact-img {
    max-width: 600px;
    margin-left: auto;
    margin-bottom: 30px;
}
.contact-section {
    position: relative;
    z-index: 1;

    &::before {
        z-index: -1;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 75%;
        background-color: #fef4f0;
    }
}
.contact-wrap {
    margin-left: 70px;
    margin-bottom: 30px;
}
.contact-form {
    padding: 50px;
    max-width: 800px;
    background-color: $color-white;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);

    .section-title {
        margin-bottom: 30px;

        h2 {
            font-size: 35px;
        }
    }
    h3 {
        font-size: 30px;
        color: $color-black;
        margin-bottom: 30px;
    }
    .form-group {
        margin-bottom: 25px;
        position: relative;

        i {
            position: absolute;
            top: 14px;
            left: 15px;
            line-height: 1;
            font-size: 18px;
            color: #7f7f7f;
            z-index: 1;
        }
        .form-control {
            height: 50px;
            color: #7f7f7f;
            border: 1px solid #e8e8e8;
            background-color: $color-white;
            border-radius: 0;
            font-size: 14px;
            padding: 10px 20px 10px 40px;
            width: 100%;

            &:focus {
                outline: none;
                border-color: #ffc0a7;
                box-shadow: none;
            }
        }
        textarea.form-control {
            height: auto;
        }
    }
    .with-errors {
        float: left;
        font-size: 16px;
        margin-top: 10px;
        margin-bottom: 0;
        color: #f00;
        font-weight: 400;
        display: block;
    }
    .text-danger {
        font-size: 18px;
        margin-top: 15px;
    }
    .default-btn {
        border: 0;
        outline: none;
    }
}
.contact-form .form-group .form-control::-webkit-input-placeholder {
    color: #7f7f7f;
}
.contact-form .form-group .form-control:-ms-input-placeholder {
    color: #7f7f7f;
}
.contact-form .form-group .form-control::-ms-input-placeholder {
    color: #7f7f7f;
}
.contact-form .form-group .form-control::placeholder {
    color: #7f7f7f;
}
.contact-map iframe {
    display: block;
    width: 100%;
    height: 470px;
    border: none;
}
.contact-widget {
    margin-bottom: 35px;
    border: 1px dashed $main-color;
    background-color: $color-white;
    box-shadow: 0 0 15px rgba(102, 102, 102, 0.1);

    h2 {
        font-size: 20px;
        color: $color-black;
        border-bottom: 1px dashed $main-color;
        padding: 15px 30px;
        font-weight: 500;
    }
    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: block;
            position: relative;
            padding-top: 25px;
            padding-bottom: 25px;
            border-bottom: 1px dashed $main-color;

            &:last-child {
                border-bottom: none;
            }
            i {
                font-size: 40px;
                color: $main-color;
                display: inline-block;
                position: absolute;
                top: 18px;
                left: 30px;
            }
            .content {
                display: inline-block;
                margin-left: 95px;

                h3 {
                    font-size: 17px;
                    color: $main-color;
                    margin-bottom: 5px;
                }
                span {
                    color: $color-title;
                    font-weight: 600;
                    font-size: 16px;

                    a {
                        display: block;
                        color: $color-title;
                        font-weight: 600;
                    }
                    &:hover {
                        color: $main-color;

                        a {
                            color: $main-color; 
                        }
                    }
                }
            }
        }
    }
}

/*==================================
User All Area 
=================================*/
.user-all-img {
    margin-bottom: 30px;
}
.user-all-form {
    margin-bottom: 30px;

    .contact-form {
        .agree-label {
            label {
                font-weight: 600;
                color: $color-title;
                margin-left: 10px;
                font-size: 17px;
            }
        }
        .forget {
            margin-bottom: 15px;
            float: right;
            color: $color-black;
            font-weight: 600;
            font-size: 17px;

            &:hover {
                color: $main-color;
            }
        }
        .account-desc {
            margin-top: 15px;
            font-weight: 600;
            text-align: center;
            margin-bottom: 0;
            font-size: 17px;

            a {
                color: $color-title;

                &:hover {
                    color: $main-color;
                }
            }
        }
    }
}
.single-content {
    margin-bottom: 30px;

    h3 {
        font-size: 26px;
        color: $color-title;
        margin-bottom: 10px;
    }
    p {
        margin-bottom: 0;
    }
}

/*==================================
Footer Area 
=================================*/
.footer-bg {
    background-image: url(assets/img/shape/footer-bg.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
}
.footer-bg2 {
    background-image: url(assets/img/shape/footer-bg2.png);
    background-position: center center;
    background-repeat: no-repeat;
    z-index: 1;
}
.footer-top {
    border-bottom: 1px solid #e6e6e6;
}
.footer-img {
    margin-bottom: 20px;
}
.footer-social-icon {
    .social-link {
        float: right;
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            display: inline-block;
            margin-left: 5px;

            a {
                border-radius: 50px;
                width: 35px;
                height: 35px;
                line-height: 39px;
                text-align: center;
                color: $color-white;
            }
            &:nth-child(1) a {
                background-color: #3b5998;

                &:hover {
                    background-color: $main-color;
                    animation: tada 1s linear;
                }
            }
            &:nth-child(2) a {
                background-color: #55acee;

                &:hover {
                    background-color: $main-color;
                    animation: tada 1s linear;
                }
            }
            &:nth-child(3) a {
                background-color: #3f729b;

                &:hover {
                    background-color: $main-color;
                    animation: tada 1s linear;
                }
            }
            &:nth-child(4) a {
                background-color: #ff0000;

                &:hover {
                    background-color: $main-color;
                    animation: tada 1s linear;
                }
            }
            &:nth-child(5) a {
                background-color: #c80000;

                &:hover {
                    background-color: $main-color;
                    animation: tada 1s linear;
                }
            }
        }
    }
}
.footer-widget {
    margin-bottom: 30px;

    h3 {
        font-size: 24px;
        color: $color-blue;
        margin-bottom: 15px;
    }
    .footer-list {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: block;
            color: $paragraph-color;
            margin-bottom: 5px;

            a {
                color: $paragraph-color;

                &:hover {
                    color: $main-color;
                    letter-spacing: 0.15px;
                }
            }
        }
    }
    p {
        max-width: 300px;
        margin-bottom: 30px;
    }
    .footer-social-icon {
        .social-link {
            float: none;
        }
    }
    .footer-list-two {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: block;
            color: $paragraph-color;
            margin-bottom: 5px;
            position: relative;
            padding-left: 25px;

            i {
                color: $main-color;
                margin-right: 5px;
                position: absolute;
                left: 0;
                top: 0;
            }
            a {
                color: $paragraph-color;
            }
            &:hover {
                color: $main-color;

                i {
                    color: $color-title;
                }
                a {
                    color: $main-color;
                }
            }
        }
    }
}
.newsletter-form {
    position: relative;
    border-radius: 50px;

    .form-control {
        background: $color-white;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
        height: 45px;
        line-height: 45px;
        margin: 0;
        border-radius: 50px;
        border: none;
        padding: 0 25px;

        &:focus {
            outline: none;
            border: none;
        }
    }
    .default-btn {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        line-height: 45px;
        padding: 0 30px;
        height: 45px;
        border: 0;             
    }
}
.copy-right-area {
    padding: 25px 15px;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: #f0f0f0;
    }
    .copy-right-text {
        p {
            color: $color-blue;
            margin-bottom: 0;

            a {
                color: $main-color;
                border-bottom: 1px solid $main-color;

                &:hover {
                    color: $color-black;
                    border-color: $color-black;
                }
            }
        }
    }
}
.copy-right-list {
    float: right;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
            margin-right: 20px;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                top: 8px;
                right: -12px;
                height: 14px;
                width: 1px;
                background-color: $color-blue;
            }
            &:last-child {
                margin-right: 0;

                &::before {
                    display: none;
                }
            }
            a {
                color: $color-blue;
            }
        }
    }
}

/*=================================
404 Error Area
===================================*/
.error-area {
    background-color: #f9f9f9;
    padding: {
        top: 50px;
        bottom: 50px;
    };
    .error-content {
        text-align: center;
        position: relative;

        img {
            max-width: 100%;
        }
        h3 {
            margin-bottom: 20px;
            position: relative;
            color: $main-color;
            font-size: 35px;
        }
        p {
            margin-top: 20px;
            margin-bottom: 20px;
            font-size: 18px;
            max-width: 520px;
            margin-left: auto;
            margin-right: auto;
            color: $color-title;
        }
    }
}

/*================================== 
Coming Soon Area 
====================================*/
.coming-soon-area {
    position: relative;
    height: 100vh;
    overflow: hidden;
    background-color: $color-blue;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-image: url(assets/img/inner-banner/banner-bg2.png);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }
    .coming-soon-content {
        text-align: center;
        max-width: 750px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        z-index: 2;
        padding: 50px 30px;
        border: 5px solid #aec5df;

        h1 {
            margin-bottom: 0;
            color: $color-white;
            font-size: 60px;
        }
        p {
            font-size: 16px;
            max-width: 600px;
            margin-top: 15px;
            margin-bottom: 0;
            margin-left: auto;
            margin-right: auto;
            color: $color-white;
        }
        #timer {
            margin-top: 20px;

            div {
                display: inline-block;
                color: $color-white;
                position: relative;
                margin-left: 35px;
                margin-right: 35px;
                font-size: 45px;
                font-weight: 700;

                span {
                    display: block;
                    text-transform: capitalize;
                    margin-top: -15px;
                    font-size: 16px;
                    font-weight: normal;
                    color: $color-white;
                }
                &:last-child {
                    margin-right: 0;

                    &::before {
                        display: none;
                    }  
                }
                &:first-child {
                    margin-left: 0;
                }
                &::before {
                    content: "";
                    position: absolute;
                    right: -50px;
                    top: -10px;
                    font-size: 70px;
                    color: $color-white;
                }
            }
        }
        .newsletter-form {
            position: relative;
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 50px;

            .input-newsletter {
                display: block;
                width: 100%;
                height: 60px;
                border: none;
                background-color: $color-white;
                padding-left: 15px;
                color: $color-white;
                outline: 0;
                -webkit-transition: 0.5s;
                transition: 0.5s;
                border-radius: 0;
                border: 1px solid $color-title;
                color: #5d5d5d;

                &:focus {
                    border-color: $main-color;
                }
            }
            .default-btn-two {
                border: 0;
                outline: 0;
                border-radius: 0 !important;
            }
            button {
                position: absolute;
                right: 0;
                top: 0;
                height: 60px;
                padding: 0 30px;
                text-transform: uppercase;
                outline: 0;
                color: $color-white;
                transition: 0.5s;
                font-weight: 500;
                font-size: 15px;
                cursor: pointer;

                &::after {
                    border-radius: 0;
                }
                &::before {
                    border-radius: 0;
                }
                &:hover {
                    color: $color-white;
                    background-color: #190f3c;
                }
            }
        }
        ul {
            list-style: none;
            margin-top: 30px;
            padding: 0;

            li {
                display: inline-block;
                width: 45px;
                height: 45px;
                line-height: 50px;
                font-size: 18px;
                background-color: $main-color;
                color: $color-white;
                border-radius: 50px;
                margin-right: 10px;

                a {
                    color: $color-white;
                }
                &:hover {
                    background-color: $color-title;
                }
            }
        }
    }
}

/*=================================
Page-navigation Area Style
====================================*/
.pagination-area {
    margin-top: 10px;
    margin-bottom: 30px;

    .page-numbers {
        width: 40px;
        height: 40px;
        line-height: 40px;
        color: $main-color;
        text-align: center;
        display: inline-block;
        border-radius: 50px;
        position: relative;
        margin-left: 3px;
        margin-right: 3px;
        font-size: 17px;
        font-weight: 500;
        border: 1px solid #e6e8ec;

        &:hover {
            color: $color-white;
            background-color: $main-color;
        }
        i {
            position: relative;
            font-size: 24px;
            top: 5px;
        }
        &.current {
            color: $color-white;
            background-color: $main-color;
        }
    }
}

/*==================================
Preloader CSS 
=====================================*/
.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background: $main-color;
} 
.spinner {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    background-color: $color-white;
    margin: 100px auto;
    animation: rotate-in 1.2s infinite ease-in-out;
}

/*==================================
Back To Top Button 
=====================================*/
#toTop {
	position: fixed;
	bottom: 20px;
	right: 20px;
	cursor: pointer;
    display: none;
    z-index: 99;
} 
.top-btn {
    background-image: linear-gradient(to right,#ff2500,#ff7100);
    color: $color-white;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    box-shadow: 0 0 15px $main-color;
    font-size: 20px;
    display: inline-block;
    text-align: center;
    line-height: 45px;
    transition: .9s;

    &:hover{
        background-image: linear-gradient(to right,#070100e7,#070300bb);
        box-shadow: 0 0 15px $color-black;
        color: $color-white;
    }
}

/*==================================
Animation CSS
====================================*/
@keyframes rotated360 {
    0% {
        -webkit-transform: rotateZ(0deg);
        transform: rotateZ(0deg);
    }
    100% {
        -webkit-transform: rotateZ(-360deg);
        transform: rotateZ(-360deg);
    }
}
@keyframes rotate-in {
    0% {
        -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }
    50% {
        -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }
    100% {
        -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}
@keyframes animationFramesOne {
    0% {
        -webkit-transform: translate(0px, 0px) rotate(0deg);
        transform: translate(0px, 0px) rotate(0deg);
    }
    20% {
        -webkit-transform: translate(73px, -1px) rotate(36deg);
        transform: translate(73px, -1px) rotate(36deg);
    }
    40% {
        -webkit-transform: translate(141px, 72px) rotate(72deg);
        transform: translate(141px, 72px) rotate(72deg);
    }
    60% {
        -webkit-transform: translate(83px, 122px) rotate(108deg);
        transform: translate(83px, 122px) rotate(108deg);
    }
    80% {
        -webkit-transform: translate(-40px, 72px) rotate(144deg);
        transform: translate(-40px, 72px) rotate(144deg);
    }
    100% {
        -webkit-transform: translate(0px, 0px) rotate(0deg);
        transform: translate(0px, 0px) rotate(0deg);
    }
}
@keyframes opacity-option {
    0% {
        opacity: 1;
    }
    50% {
        opacity: .05;
    }
    100% {
        opacity: 1;
    }
}
@keyframes running-in {
    0% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
    50% {
        -webkit-transform: translate(-10px, 150px);
        transform: translate(-10px, 150px);
    }
    100% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
}
@keyframes movebounce-in {
    0% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }
    50% {
        -webkit-transform: translateX(70px);
        transform: translateX(70px);
    }
    100% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }
}
@keyframes movebounce-up {
    0% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
    50% {
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
    }
    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
}
@keyframes ripple {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    75% {
      -webkit-transform: scale(1.75);
      transform: scale(1.75);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(2);
      transform: scale(2);
      opacity: 0;
    }
}

/*==================================
My Area 
=================================*/
.my-accordion {
    max-width: 1180px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 30px;

    .accordion {
        list-style-type: none;
        padding-left: 0;
        margin-bottom: 0;

        .accordion-item {
            display: block;
            background-color: $color-white;
            margin-bottom: 15px;
            box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);

            &:last-child {
                margin-bottom: 0;
            }
        }
        .accordion-title {
            padding: 20px 60px 17px 20px;
            color: #232350;
            text-decoration: none;
            position: relative;
            display: block;
            font-size: 18px;
            font-weight: 600;

            i {
                position: absolute;
                right: 15px;
                top: 15px;
                font-size: 25px;
                transition: 0.5s;
                font-size: 24px;
                width: 40px;
                height: 40px;
                color: $color-white;
                text-align: center;
                line-height: 40px;
                background-color: $color-title;
            }
            &.active i {
                transform: rotate(180deg);
                top: 15px;
            }
        }
        .accordion-content {
            display: none;
            position: relative;
            margin-top: -5px;
            padding-bottom: 10px;
            padding-right: 30px;
            padding-left: 30px;
            
            p {
                line-height: 1.8;
            }
            &.show {
                display: block;
            }
        }
    }
}